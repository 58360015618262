import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import algorand from '../../assets/arts/airdrop/wallets/algorand.png';
import arget from '../../assets/arts/airdrop/wallets/arget.png';
import avalance from '../../assets/arts/airdrop/wallets/avalance.png';
import fantom from '../../assets/arts/airdrop/wallets/fantom.png';
import firefly from '../../assets/arts/airdrop/wallets/firefly.png';
import keplr from '../../assets/arts/airdrop/wallets/keplr.png';
import metamask from '../../assets/arts/airdrop/wallets/metamask-logo 1.png';
import near from '../../assets/arts/airdrop/wallets/near.png';
import near_dark from '../../assets/arts/airdrop/wallets/near_dark.png';
import phantom from '../../assets/arts/airdrop/wallets/phantom.png';
import polkadot from '../../assets/arts/airdrop/wallets/polkadot.png';
import terra from '../../assets/arts/airdrop/wallets/terra.png';
import FormControl from "@mui/material/FormControl";
import "./RegisterAirDrop.css";
import { airdropRegister } from '../../services/api';
import { ethers } from 'ethers';


const ChooseAirDropMobile = () => {

  const [selectedValue, setSelectedValue] = useState("metamask");

  const [walletAdd, setWalletAdd] = useState('');
  const [isWalletAddValid, setIsWalletAddValid] = useState(true);
  const airdropAmount = 5;

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async () => {
    let register = await airdropRegister(undefined, "External", walletAdd, selectedValue, airdropAmount, "IUSD+")
    console.log(register);
    alert(register.data.message)
  }

  const handleWalletAddressChange = (e) => {
    const address = e.target.value;
    setWalletAdd(address);
    const isValid = isValidEthereumAddress(address);
    setIsWalletAddValid(isValid);
  };

  const isValidEthereumAddress = (address) => {
    try {
      return ethers.utils.isAddress(address);
    } catch (e) {
      return false;
    }
  };

  const paymentOptions = [
    { value: 'metamask', imageSrc: metamask, description: 'Metamask' },
    { value: 'arget', imageSrc: arget, description: 'Arget' },
    { value: 'keplr', imageSrc: keplr, description: 'Keplr Wallet' },
    { value: 'phantom', imageSrc: phantom, description: 'Phantom Wallet' },
    { value: 'terra', imageSrc: terra, description: 'Terra Station' },
    { value: 'polkadot', imageSrc: polkadot, description: 'Polkadot Wallet' },
    { value: 'avalance', imageSrc: avalance, description: 'Avalance Wallet' },
    { value: 'near', imageSrc: near, imageSrcdark: near_dark, description: 'Near Wallet' },
    { value: 'algorand', imageSrc: algorand, description: 'Algorand Wallet' },
    { value: 'fantom', imageSrc: fantom, description: 'Fantom Wallet' },
    { value: 'firefly', imageSrc: firefly, description: 'Firefly iota Wallet' },
  ];

  return (
    <div style={{ paddingTop: "120px" }}>

      <div className="d-flex flex-column justify-content-center align-items-center mb-5 fw-bold">
      <div style={{width:"84%", fontSize:"35px"}}> 
        Airdrop Registration
      </div>
      </div>
      <div className="d-flex justify-content-center">
      <FormControl className=" mt-5">
      <span className="font_15x">

      Select Wallet* 
      </span>
      <Select
        labelId="payment-method-label"
        id="payment-method"
        value={selectedValue}
        onChange={handleSelectChange}
        className="select-input"
      >
        {paymentOptions.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{py:2}}>
            <img alt="Female" src={theme === "dark" ? ( option.imageSrcdark || option.imageSrc ) : option.imageSrc} className="drop2-image" />
            {option.description}
          </MenuItem>
        ))}

      </Select>
    </FormControl>
        </div>

        <div className="d-flex flex-column justify-content-start align-items-center mt-5 pb-5">
      <div style={{width:"84%"}}> 

          <Typography variant="text" fontSize={"15px"} fontWeight={500} textAlign={"left"}>
            Wallet Address
          </Typography>

          <TextField
            label=""
            placeholder='Wallet Address'
            variant="outlined"
            sx={{ width: '100%', mb:4, mt:2, fontSize:"18px" }}
            size="large" // Make the input box smaller
            value={walletAdd}
            onChange={handleWalletAddressChange}
          />

        <Button variant='contained'
          onClick={handleSubmit}
          disableTouchRipple
          disabled={!isValidEthereumAddress(walletAdd)}
          sx={{
            backgroundColor: "#0078E5",
            borderRadius: "2px",
            // color: "#282828",
            width: "100%",
            px: 10,
            py: 1,
            textTransform: "none",
            fontSize: "13px",
            fontWeight: 700,
            boxShadow: "none",
            // mt: 3,
            mb: 10,
            height: "55px",
            "&:hover": {
              backgroundColor: "#0048B4",
              boxShadow: "none",
            },
          }}
        >
          Submit
        </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseAirDropMobile;
