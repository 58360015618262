import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import hive_exch from "../../assets/arts/airdrop/indexx exchange logo new 1.png";
import hat from "../../assets/arts/airdrop/hat.png";
import bee from "../../assets/arts/airdrop/bee.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./RegisterAirDrop.css";
import {
  airdropRegister,
  baseCEXURL,
  baseHiveURL,
  checkemail,
  bitcoinAirdropRegister
} from "../../services/api";

const IusdHiveRegister = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const [emailAdd, setEmailAdd] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedValue, setSelectedValue] = useState("Hive Exchange");
  const [isLoading, setIsLoading] = useState(false);
  const airdropAmount = 15;

  const validateEmail = (email) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(isValid ? "" : "Invalid email address");
    return isValid;
  };

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ ...notification, open: false });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const res = await checkemail(emailAdd);

      if (res.success) {
        setNotification({
          open: true,
          message: "User email is not registered",
          severity: "error",
        });
      } else {
        if (res?.userType === "CaptainBee" || res?.userType === "HoneyBee") {
          let register = await bitcoinAirdropRegister(
            emailAdd,
            res.userType,
            undefined,
            undefined,
            airdropAmount,
            "BTC",
            "BitcoinAirdrop"
          );
          setNotification({
            open: true,
            message: register.data.message,
            severity: "success",
          });
        } else {
          setNotification({
            open: true,
            message: `Only Captain bee or Honey bee can register. The email provided is ${res?.userType}. Please use Exchange to register for airdrop.`,
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error in submitting:", error);
      setNotification({
        open: true,
        message: "An error occurred during submission. Please try again later.",
        severity: "error",
      });
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  return (
    <div style={{ paddingTop: "120px" }}>
      <Snackbar
        open={notification.open}
        autoHideDuration={10000}
        onClose={handleClose}
        sx={{
          top: "50% !important",
          left: "50% !important",
          transform: "translate(-50%, -50%) !important",
        }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={notification.severity}
          sx={{
            width: "100%",
            border: "2px solid #FFD000",
            backgroundColor: "white",
          }}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>

      <div className="d-flex flex-column justify-content-center align-items-center font_40x text-center fw-bold mb-5">
        <img src={hive_exch} alt="hive" style={{ width: "fit-content" }} />
        <div className="mt-2">
          Airdrop Registration
          <div className={isMobile ? "font_50x fw-bold" : ""}>
            Hive Exchange
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center mt-2 pb-2">
        <Box
          width={isMobile ? "84%" : "590px"}
          className="d-flex flex-column align-items-center mb-5"
        >
          <Typography
            variant="text"
            fontSize={"15px"}
            fontWeight={500}
            textAlign={"left"}
            width={"100%"}
          >
            Email Address:
          </Typography>

          <TextField
            label=""
            placeholder="Enter your CaptainBee or HoneyBee Email Address"
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
            value={emailAdd}
            error={emailError !== ""}
            helperText={emailError}
            onChange={(e) => {
              setEmailAdd(e.target.value);
              validateEmail(e.target.value);
            }}
          />

          <Button
            variant="contained"
            disableTouchRipple
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{
              backgroundColor: "#FFB300",
              borderRadius: "2px",
              width: "100%",
              mt: 2,
              mb: 2,
              height: `${isMobile ? "55px" : "40px"}`,
              boxShadow: "none", // Remove the shadow
              "&:hover": {
                backgroundColor: "#FFD000",
                boxShadow: "none", // Remove the on-hover shadow
              },
            }}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>

          <div className="font_11x" style={{}}>
            If you are not part of the hive, please register as a captain bee or
            honey bee here and fill in your email address once you have signed
            up.
          </div>
        </Box>

        <div
          className="d-flex justify-content-between my-1"
          style={{
            width: `${isMobile ? "84%" : "590px"}`,
            flexDirection: `${isMobile ? "column" : "row"}`,
            gap: `${isMobile ? "50px" : "0"}`,
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <img src={bee} alt="bee" className="hive-img" />
            <a
              href={`${baseCEXURL}/indexx-exchange/buy-sell/get-started-honeybee`}
              className="hive-link font_15x"
            >
              Signup as Honeybee
            </a>
            <a
              href={`${baseHiveURL}/honey-bees`}
              className="hive-link font_15x"
            >
              Learn More
            </a>
          </div>

          <div className="d-flex flex-column align-items-center">
            <img src={hat} alt="hat" className="hive-img" />
            <a href={`${baseHiveURL}/sign-up`} className="hive-link font_15x">
              Signup as Captainbee
            </a>
            <a href={`${baseHiveURL}/bees`} className="hive-link font_15x">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IusdHiveRegister;
