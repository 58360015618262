import React, { useContext } from "react";
import commingSoon from "../../../assets/meme/mello_comingsoon.png";
import { Theme } from "../../../utils/themeContext";
import melloComingSoonDark from "../../../assets/meme/mello_dark_coming_soon.png";
const MelloCommingSoon = () => {
  const themeContest = useContext(Theme);
  const theme = themeContest?.theme ?? "dark";

  return (
    <div
      style={{
        width: "100%",
        margin: "150px auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={theme === "light" ? commingSoon : melloComingSoonDark} />
    </div>
  );
};

export default MelloCommingSoon;
