import { Image, Button } from "antd";
import { Typography } from "antd";

import market_black from "../../assets/how-it-works/x market black 1.svg";
import market_white from "../../assets/how-it-works/x market white 1.svg";

import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";

const { Text } = Typography;



const HowMarket = () => {
  

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event:any) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  console.log(theme);

  return (
    <>
      <div className="scan-container how-it-works flex-direction-column">
        <Image
          preview={false}
          src={theme === "dark" ? market_white : market_black}
          className="rounded mx-auto d-block"
          style={{ width: "180px", paddingTop: 40, display: "flex", marginBottom:25 }}
        ></Image>
        <Text
          className="card__title"
          style={{
            marginBottom: 50,
            color: "var(--body_color)",
            fontSize: "70px",
            lineHeight: "1em",
          }}
        >
          XNFT Market{" "}
        </Text>

        <div className="container">
          <div className="text-left justify-center ">
            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
             IndexXNFTMarket is a decentralized marketplace designed for trading indexXNFT and other digital assets.
              The platform provides a secure and efficient ecosystem for users to buy, sell, and exchange indexXNFT representing various asset classes, including cryptocurrencies, stocks, commodities, and more.
              IndexXNFTMarket leverages blockchain technology and smart contracts to enable transparent, trustless, and non-custodial trading of indexXNFT.

            </Text>
            <br />
            <br />
            <br />

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 30,
                fontWeight: 400,
                lineHeight: 2,
              }}
            >
              Features and Functionality:
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              1.Trading interface:  <br />
              IndexXNFTMarket offers an intuitive and user-friendly interface where users can browse, search, and discover a wide range of indexXNFT available for trading.
Liquidity provision: users can provide liquidity to the marketplace by depositing their indexXNFT into liquidity pools, allowing others to trade against their holdings. In return, liquidity providers earn fees and incentives.

            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              2. Automated market making <br />
              IndexXNFTMarket utilizes automated market maker (amm) mechanisms to facilitate trading. This ensures continuous liquidity and efficient price discovery for indexXNFT.
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              3.Decentralized governance <br />
              The platform incorporates decentralized governance mechanisms, allowing IndexXNFTMarket users to participate in decision-making processes regarding platform upgrades, fee structures, and other important parameters.
            </Text>


            <br/>
            <br/>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 30,
                fontWeight: 400,
                lineHeight: 2,
              }}
            >
              Benefits and considerations:
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              1.Decentralization and Security <br />
              IndexXNFTMarket operates on a decentralized network, reducing the risk of single points of failure or hacking vulnerabilities. Smart contracts and blockchain technology ensure transparent and secure trading.
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              2. Increased market accessibility <br />
              By providing a marketplace specifically tailored for indexXNFT, IndexXNFTMarket enhances the accessibility of these unique assets, enabling users to easily discover, trade, and diversify their holdings.
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              3.Global market reach <br />
              IndexXNFTMarket operates on a global scale, allowing users from different regions and jurisdictions to participate in trading indexXNFT and access diverse asset classes.
            </Text>
            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              4. Interoperability <br />
              IndexXNFTMarket can be designed to support multiple blockchain networks, enabling interoperability between different blockchain ecosystems and expanding the reach and potential user base of the marketplace.
            </Text>
            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              5. Community and collaboration  <br />
              IndexXNFTMarket fosters a community-driven environment, encouraging collaboration among users, developers, and stakeholders to drive the growth and development of the platform.
            </Text>

            
            <br/>
            <br/>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 30,
                fontWeight: 400,
                lineHeight: 2,
              }}
            >
              Future potential:
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              1.Expansion of asset classes <br />
              IndexXNFTMarket has the potential to expand beyond digital assets and incorporate traditional financial instruments such as bonds, derivatives, and other types of securities, further broadening the scope and opportunities for traders and investors.
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              2. Integration with DEFI ecosystem <br />
              IndexXNFTMarket can integrate with decentralized finance protocols to enable users to leverage their indexXNFT for various financial activities, such as borrowing, lending, yield farming, and staking.
            </Text>

            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              3.Cross-chain compatibility <br />
              As blockchain interoperability improves, IndexXNFTMarket can explore cross-chain functionality, allowing users to trade indexXNFT across different blockchain networks and tap into the liquidity and features offered by each ecosystem.
            </Text>
            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 20,
                fontWeight: 400,

                lineHeight: 2,
              }}
            >
              <br />
              <br />
              4. XNFT marketplace expansion <br />
              IndexXNFTMarket can expand its offerings beyond indexXNFT to include other types of nfts, such as art, collectibles, and virtual real estate, creating a comprehensive marketplace for a wide range of digital assets.
            </Text>

            <br />
          </div>
        </div>

        <div
          className="text-center"
          style={{
            width: 300,
            marginTop: 100,
            borderRadius: 0,
            borderColor: "#006DFF",
          }}
        >
          <a href="https://xnftmarketplace.indexx.ai/">
            {" "}
            <Button
              style={{ width: 300, height: 60 }}
              type="primary"
              size="large"
            >
              Go to Market
            </Button>{" "}
          </a>

          <br />
          <br />
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default HowMarket;
