import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid, useMediaQuery } from '@mui/material';

const CountdownTimer = ({ targetDate }) => {

  const isMobile = useMediaQuery('(max-width:768px)');

  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const difference = targetDate.getTime() - now;

    return difference > 0 ? difference : 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining);
    }, 1000);

    return () => clearInterval(timer);
  }, []); // Empty dependency array means this effect runs once after the initial render

  const days = Math.floor(timeRemaining / (24 * 60 * 60 * 1000));
  const hours = Math.floor((timeRemaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((timeRemaining % (60 * 1000)) / 1000);

  const formatDigit = (digit) => (
    <Paper elevation={3} style={{ padding: `${isMobile ? "8px" : "10px"}`, margin: 5, background : 'linear-gradient(180deg, var(--timer-color) 50%, #626262 50%)' }}>
      <Typography variant={isMobile ? "h6" : "h3"} color={"#fff"}>{digit}</Typography>
    </Paper>
  );

  const formatTimeUnit = (desc, timeUnit) => {
    const paddedTimeUnit = String(timeUnit).padStart(2, '0'); // Add leading zero if needed

    const digits = paddedTimeUnit.split('');
    return (
      <div className='justify-content-center'>
      <Typography fontSize={"15px"} textAlign={"center"} >{desc}</Typography>
      <Grid container width={"auto"} px={isMobile ? 0 : 1} sx={{flexWrap:"nowrap"}}>
        {digits.map((digit, index) => (
          <Grid item key={index}>
            {formatDigit(digit)}
          </Grid>
        ))}
      </Grid>
      </div>

    );
  };
  
  return (
    <div>
    <div style={{ display: 'flex' }}>
      {formatTimeUnit("Days", days)} <span>&nbsp;&nbsp;&nbsp;</span> {formatTimeUnit("Hours", hours)} <span className='font_50x' style={{marginTop : `${isMobile ? "8px" : "20px"}`}}> : </span> {formatTimeUnit("Minutes", minutes)} <span className='font_50x' style={{marginTop : `${isMobile ? "8px" : "20px"}`}}> : </span> {formatTimeUnit("Seconds" , seconds)}
    </div>
    </div>
  );
};

export default CountdownTimer;
