import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import LoginComponent from '../../../components/updated/authentication/loginEmail';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserShortToken, decodeJWT, baseNewCEXURL, baseLottoUrl, baseAcademyUrl } from '../../../services/api';
import RedirectionPopup from '../../../components/updated/shared/RedirectionPopup';

const handleNavigation = async (url: any, setLoading: (loading: boolean) => void) => {
  setLoading(true);
  const isAuthenticated = localStorage.getItem("access_token");

  const email = localStorage.getItem("email");
  let shortToken;
  if (email) {
    shortToken = await getUserShortToken(email);
  } else if (isAuthenticated) {
    let decodedValue: any = await decodeJWT(isAuthenticated);
    shortToken = await getUserShortToken(decodedValue?.email);
  }

  if (isAuthenticated) {
    const urlObj = new URL(url);
    urlObj.searchParams.set("signInToken", shortToken?.data);
    const urlWithToken = urlObj.toString();
    window.location.href = urlWithToken;
  } else {
    window.location.href = url;
  }
};

const Login = () => {
  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const originalRedirectLink = searchParams.get('redirectWebsiteLink');
  const [loading, setLoading] = useState(false);

  let redirectWebsiteLink :any;
  if (originalRedirectLink === 'exchange') {
    redirectWebsiteLink = baseNewCEXURL;
  } else if (originalRedirectLink === 'lotto') {
    redirectWebsiteLink = baseLottoUrl;
  } else if (originalRedirectLink === 'academy') {
    redirectWebsiteLink = baseAcademyUrl;
  }

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = localStorage.getItem("access_token");
      if (isAuthenticated) {
        await handleNavigation(redirectWebsiteLink || '/', setLoading);
      }
    };

    const action = searchParams.get('action');
    if (action === 'Logout') {
      localStorage.removeItem("user"); // Remove one item
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("email");
      localStorage.clear(); // Clear all local storage
      window.location.href = "/auth/login";
    } else {
      checkAuthentication();
    }
  }, [redirectWebsiteLink]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme?.palette?.background.default,
        color: theme?.palette?.text?.primary,
      }}
    >
      {loading && <RedirectionPopup name={originalRedirectLink} onClose={() => setLoading(false)} />}
      <LoginComponent redirectWebsiteLink={redirectWebsiteLink} />
    </div>
  );
};

export default Login;
