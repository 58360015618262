import React from "react";
import starBg from "../../assets/discountShop/starsbg.svg";
interface IDiscountHero {
  bgColor: string;
  textColor: string;
}
function DiscountHero({ bgColor, textColor }: IDiscountHero) {
  return (
    <div
      className="discount_hero_root"
      style={{
        background: bgColor,
      }}
    >
      <div className="discount_hero_star_overlay">
        <img src={starBg} alt="star" />
      </div>
      <div className="discount_hero_text_container">
        <p className="discount_hero_text">
          Today's your <span className="lucky_day_text">Lucky Day!</span> Get
          upto{" "}
          <span className="percentOff" style={{ color: textColor }}>
            90% off
          </span>{" "}
          <span className="when_you_text">when you purchase today.</span>
        </p>
      </div>
    </div>
  );
}

export default DiscountHero;
