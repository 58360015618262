import React, { useState, useEffect } from "react";
import "./sections1.css";
// import BackgroundImage from "../../../assets/background.png";
import Section1Desktop from "./section1Desktop";
import Section1Mobile from "./section1Mobile";

const Section1 = () => {
  // let comingSoonText = <p className="font_13x brand_color">Coming soon 31st Oct</p>;
  return <Section1Desktop />;
};

export default Section1;
