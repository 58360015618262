import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import wallet from "../../assets/arts/airdrop/wallet black 1.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./RegisterAirDrop.css";
import {
  airdropRegister,
  baseWalletURL,
  checkByWebWalletEmail,
  checkemail,
} from "../../services/api";

const SuperBallWalletRegister = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const [emailAdd, setEmailAdd] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const airdropAmount = 15;

  const validateEmail = (mail) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail);
    setEmailError(isValid ? "" : "Invalid email address");
    return isValid;
  };

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ ...notification, open: false });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await checkByWebWalletEmail(emailAdd);
      if (res.success) {
        setNotification({
          open: true,
          message: "User email is not registered",
          severity: "error",
        });
      } else {
        if (res?.userType === "Web Wallet") {
          let register = await airdropRegister(
            emailAdd,
            res.userType,
            undefined,
            undefined,
            airdropAmount,
            "IUSD+"
          );
          setNotification({
            open: true,
            message: register.data.message,
            severity: "success",
          });
        } else {
          const res = await checkemail(emailAdd);
          if (res?.userType === "CaptainBee" || res?.userType === "HoneyBee") {
            setNotification({
              open: true,
              message: `Only Web Wallet users can register. The email provided is ${res?.userType}. Please use Hive Exchange to register for airdrop.`,
              severity: "error",
            });
          } else {
            setNotification({
              open: true,
              message: `Only Web Wallet users can register. The email provided is ${res?.userType}. Please use Exchange to register for airdrop.`,
              severity: "error",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error in submitting:", error);
      setNotification({
        open: true,
        message: "An error occurred during submission. Please try again later.",
        severity: "error",
      });
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div style={{ paddingTop: "180px" }}>
      <Snackbar
        open={notification.open}
        autoHideDuration={10000}
        onClose={handleClose}
        sx={{
          top: "50% !important",
          left: "50% !important",
          transform: "translate(-50%, -50%) !important",
        }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={notification.severity}
          sx={{
            width: "100%",
            border: "2px solid #0078E5",
            backgroundColor: "white",
          }}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>

      <div className="d-flex flex-column justify-content-center align-items-center font_40x text-center fw-bold mb-5">
        <img src={wallet} alt="hive" style={{ width: "fit-content" }} />
        <div className="mt-2">
          Airdrop Registration
          <div className={isMobile ? "font_50x fw-bold" : ""}>Web Wallet</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center mt-5 pb-5">
        <Box
          width={isMobile ? "84%" : "590px"}
          className="d-flex flex-column align-items-center"
          mb={isMobile ? 0 : 5}
        >
          <Typography
            variant="text"
            fontSize={"15px"}
            fontWeight={500}
            textAlign={"left"}
            width={"100%"}
          >
            Email Address:
          </Typography>

          <TextField
            label=""
            placeholder="Enter your Wallet email address here"
            variant="outlined"
            sx={{
              width: "100%",
              border: "none",
            }}
            size="small"
            value={emailAdd}
            error={emailError !== ""}
            helperText={emailError}
            onChange={(e) => {
              setEmailAdd(e.target.value);
              validateEmail(e.target.value);
            }}
          />
          <br />
          <Button
            variant="contained"
            disableTouchRipple
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{
              backgroundColor: "#0078E5",
              borderRadius: "2px",
              width: "100%", // Set the button width to 100%
              px: 10,
              py: 1,
              textTransform: "none",
              fontSize: "13px",
              fontWeight: 700,
              boxShadow: "none",
              mt: 2,
              mb: 2,
              height: `${isMobile ? "55px" : "40px"}`,
              "&:hover": {
                backgroundColor: "#0048B4",
                boxShadow: "none",
              },
            }}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </Box>

        <div
          className="font_11x text-center"
          style={{ width: `${isMobile ? "100%" : "316px"}` }}
        >
          If you don't have a Wallet account, please
          {!isMobile ? <br /> : <>&nbsp;</>}
          <a href={`${baseWalletURL}`}>Signup here</a>
        </div>
      </div>
    </div>
  );
};

export default SuperBallWalletRegister;
