import React, { useEffect, useState } from "react";
import "./AirDrop.css";
import eagle from "../../assets/arts/airdrop/eagle drop 1.svg";
import form from "../../assets/arts/airdrop/form.png";
import bigbee from "../../assets/arts/airdrop/bee new-min.png";
import mobile_bee from "../../assets/arts/airdrop/bee-mobile.png";
import x from "../../assets/arts/airdrop/x.png";
import x_dark from "../../assets/arts/airdrop/x_dark.png";
import fb from "../../assets/arts/airdrop/fb.png";
import hive from "../../assets/arts/airdrop/hive.png";
import scan from "../../assets/arts/airdrop/scan.png";
import inex from "../../assets/token-icons/INEX.png";
import cal from "../../assets/arts/airdrop/cal-img.png";
import cal_dark from "../../assets/arts/airdrop/cal-img-white.png";
import cal_mob from "../../assets/arts/airdrop/cal_mob.png";
import cal_mob_dark from "../../assets/arts/airdrop/cal_mob_dark.png";
import CountdownTimer from "./CountdownTimer";
import ReadOnlyCalendar from "./ReadOnlyCalendar";
import ContactUs from "./ContactUs/ContactUs";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

const AirDrop = () => {
  const targetDate = new Date("2024-03-01T00:00:00");

  const isMobile = useMediaQuery("(max-width:768px)");

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div style={{ maxWidth: `${isMobile ? "84%" : "1550px"}` }}>
        <div className="air-drop">
          {isMobile && (
            <div className="eagle-img">
              <img alt="eagle" width={500} src={bigbee} />
            </div>
          )}
          <div style={isMobile ? { marginTop: -200 } : null}>
            <div
              className={
                isMobile ? "font_47x fw-bold text-center" : "font_100x fw-bold"
              }
            >
              FANTASY LOTTO INEX AIRDROP
            </div>
            <div className={isMobile ? "font_16x text-center" : "font_20x"}>
              Exciting News! Participate in the INEX Airdrop and claim your
              share of the future! Simply follow the steps below to secure your
              INEX tokens
            </div>
            <div className="timer" style={{ marginBlock: "20px" }}>
              <CountdownTimer targetDate={targetDate} />
            </div>
            <br />
            <br />
            <br />

            <Button
              variant="contained"
              component={Link}
              disabled={true}
              to="/airdrop-register"
              disableTouchRipple
              sx={{
                backgroundColor: "#0078E5",
                borderRadius: "2px",
                // color: "#282828",
                width: `${isMobile ? "100%" : "316px"}`,
                px: 10,
                py: 1,
                ml: `${isMobile ? "0%" : "27.2%"}`,

                textTransform: "none",
                fontSize: "13px",
                fontWeight: 700,
                boxShadow: "none",
                mt: 5,
                height: `${isMobile ? "55px" : "40px"}`,
                "&:hover": {
                  backgroundColor: "#0048B4",
                  boxShadow: "none",
                  color: "#fff",
                },
              }}
            >
              Register for airdrop
            </Button>
            {/* <a className="timer-link" href="/airdrop-register">Register for airdrop &gt;</a> */}
          </div>
        </div>

        <div
          style={{
            width: isMobile ? "100%" : "70%",
            marginTop: isMobile ? "0%" : -600,
          }}
        >
          {!isMobile ? (
            <div style={{ width: "100%" }}>
              <img alt="eagle" src={bigbee} width="100%" />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="est-val">
          {isMobile ? (
            <div className="d-flex align-items-center flex-column">
              <img alt="inex" src={inex} width={"162px"} />
              <div className={isMobile ? "font_100x fw-bold" : "font_200x"}>
                $3.00
              </div>
              <div className="font_20x">Estimated Value</div>
            </div>
          ) : (
            <div>
              <div className="font_20x" style={{ marginTop: "20px" }}>
                <img alt="inex" src={inex} width={"62px"} className="me-3" />
                Estimated Value
              </div>
              <div className="font_200x" style={{ marginTop: "20px" }}>
                $3.00
              </div>
            </div>
          )}
        </div>

        <div className="what-inex">
          {isMobile && <img alt="inex" src={inex} width={"62px"} />}

          <div
            className={isMobile ? "font_37x fw-bold text-center" : "font_70x"}
          >
            What is INEX Token
          </div>
          <div className="font_17x">
            Indexx Exchange Token/INEX is the cryptocurrency Token that powers
            the indexx.ai ecosystem. As one of the world's most popular utility
            tokens, not only can you trade INEX like any other cryptocurrency,
            you can also use INEX in a wide range of applications and use
            cases. Currently, the value of INEX is $2.00, it will increase base
            on the numbers of purchase
          </div>
          <div className="my-5">
            <a href="/indexx-exchange/token-details/inex">Learn More &gt;</a>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          {isMobile ? (
            <img
              alt="cal"
              width={400}
              src={theme === "dark" ? cal_dark : cal}
            />
          ) : (
            <img alt="cal" src={theme === "dark" ? cal_dark : cal} />
          )}
        </div>

        <div className="my-calendar">
          {isMobile ? (
            <>
              <div className="font_50x fw-bold align-self-start">Key dates</div>
              <div className="font_31x align-self-start">for airdrop</div>
              <div className="font_20x mb-5 align-self-start">
                Don’t miss out any of these dates
              </div>
            </>
          ) : (
            <>
              <div className="font_70x fw-bold">Key dates for airdrop</div>
              <div className="font_20x mb-5 ">
                Don’t miss out any of these dates
              </div>
            </>
          )}
          <ReadOnlyCalendar />
        </div>

        <div className="req">
          <div className="req-desc">
            <div className="font_60x fw-bold">Airdrop requirements</div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={fb}
                alt="fb"
                style={{ marginLeft: "10px", marginRight: "30px" }}
              />
              1. Join our official Facebook community
              <div className="font_15x ind-req-desc">
                <a href="https://www.facebook.com/profile.php?id=100086225564460">
                  https://www.facebook.com/profile.php?id=100086225564460
                </a>
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={theme === "dark" ? x_dark : x}
                alt="x"
                style={{ marginLeft: "15px", marginRight: "30px" }}
              />
              2. Follow us on Twitter
              <div className="font_15x ind-req-desc">
                <a href="https://twitter.com/Indexx_ai">
                  https://twitter.com/Indexx_ai
                </a>
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={hive}
                alt="hive"
                style={{ marginLeft: "15px", marginRight: "23px" }}
              />
              3. Join Indexx Hive on Facebook
              <div
                className={
                  isMobile ? "font_15x ind-req" : "font_15x ind-req-desc"
                }
              >
                <img src={scan} alt="hive" />
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={form}
                alt="form"
                style={{ marginLeft: "10px", marginRight: "23px" }}
              />
              4. Complete the registration form
            </div>
            <Button
              variant="contained"
              component={Link}
              to="/airdrop-register"
              disableTouchRipple
              sx={{
                backgroundColor: "#0078E5",
                borderRadius: "2px",
                // color: "#282828",
                width: `${isMobile ? "100%" : "316px"}`,
                px: 10,
                py: 1,
                ml: `${isMobile ? "0%" : "14%"}`,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 700,
                boxShadow: "none",
                mt: 5,
                height: `${isMobile ? "55px" : "40px"}`,
                "&:hover": {
                  backgroundColor: "#0048B4",
                  boxShadow: "none",
                  color: "#fff",
                },
              }}
            >
              Register Now
            </Button>
          </div>
          <div style={{ marginTop: "-40%" }}>
            <img src={eagle} alt="eagle" className="req-img" />
          </div>
        </div>

        <div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default AirDrop;
