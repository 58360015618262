import { Image } from "antd";

import t2e from "../../assets/how-it-works/howfortune.svg";
import t2e_dark from "../../assets/how-it-works/Group 200.svg";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";

const HowFortuneDaily = () => {

  // const [width, setWidth] = useState<number>(window.innerWidth);
  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }
  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event:any) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);
    // window.addEventListener('resize', handleWindowSizeChange);
    
    return () => {
      // window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  console.log(theme);

  return (
    <>
      <div className="scan-container how-it-works flex-direction-column">
        {theme === "dark" ?
        <Image
        preview={false}
        src={t2e_dark}
        className="rounded mx-auto d-block"
        
        ></Image>
        :
        <Image
        preview={false}
        src={t2e}
        className="rounded mx-auto d-block"
        
        ></Image>
      }
     
      </div>

      <Footer></Footer>
    </>
  );
};

export default HowFortuneDaily;
