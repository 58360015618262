import React, { useContext } from "react";

import MemeIcon from "../../../assets/meme/meme_up_black.svg";
import meme from "../../../assets/meme/meme_coin_up_black.svg";
import ordinal from "../../../assets/meme/ordinal_up_black.svg";
import nft from "../../../assets/meme/nft_up_black.svg";

import MemeIconwhite from "../../../assets/meme/meme_up_white.svg";
import memeWhite from "../../../assets/meme/meme_coin_up_white.svg";
import ordinalWhite from "../../../assets/meme/ordinal_up_white.svg";
import nftwhite from "../../../assets/meme/nft_up_white.svg";
import { Theme } from "../../../utils/themeContext";
const IconicHeader = ({ type }: { type: string }) => {
  const themeContext = useContext(Theme);
  const theme = themeContext?.theme ?? "dark";
  return (
    <div>
      <div
        style={{
          width: "100%",
          maxWidth: "850px",
          margin: "auto",
          marginTop: "100px",
          display: "flex",
          justifyContent: "space-evenly",
          padding: "20px",

          textAlign: "center",
        }}
      >
        <div
          style={{
            minWidth: "100px",
            cursor: "pointer",
            paddingBottom: "5px",
          }}
        >
          <a href="/meme-details" style={{ color: "black" }}>
            <img
              className="hoverImage"
              src={theme !== "dark" ? MemeIcon : MemeIconwhite}
              style={{ height: "50px", marginBottom: "5px" }}
            />
            <p style={{ color: theme === "dark" ? "white" : "black" }}>Meme</p>
          </a>
        </div>

        <div
          style={{
            minWidth: "100px",
            cursor: "pointer",
            paddingBottom: "5px",
            borderBottom:
              type === "meme"
                ? `1px solid ${theme === "dark" ? "white" : "black"}`
                : "none",
          }}
        >
          <a href="/meme-details/meme-coin" style={{ color: "black" }}>
            <img
              className="hoverImage"
              src={theme !== "dark" ? meme : memeWhite}
              style={{ height: "50px", marginBottom: "5px" }}
            />
            <p style={{ color: theme === "dark" ? "white" : "black" }}>
              Meme Coin
            </p>
          </a>
        </div>
        <div
          style={{
            minWidth: "100px",
            cursor: "pointer",
            paddingBottom: "5px",
            borderBottom:
              type === "ordinal"
                ? `1px solid ${theme === "dark" ? "white" : "black"}`
                : "none",
          }}
        >
          <a href="/meme-details/ordinal-coin" style={{ color: "black" }}>
            <img
              className="hoverImage"
              src={theme !== "dark" ? ordinal : ordinalWhite}
              style={{ height: "50px", marginBottom: "5px" }}
            />
            <p style={{ color: theme === "dark" ? "white" : "black" }}>
              Ordinal
            </p>
          </a>
        </div>
        <div
          style={{
            minWidth: "100px",
            cursor: "pointer",
            paddingBottom: "5px",
            borderBottom:
              type === "nft"
                ? `1px solid ${theme === "dark" ? "white" : "black"}`
                : "none",
          }}
        >
          <a href="/meme-details/nft" style={{ color: "black" }}>
            <img
              className="hoverImage"
              src={theme !== "dark" ? nft : nftwhite}
              style={{ height: "50px", marginBottom: "5px" }}
            />
            <p style={{ color: theme === "dark" ? "white" : "black" }}>NFT</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default IconicHeader;
