import { Box, Button, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import hive_exch from "../../assets/arts/airdrop/indexx exchange logo new 1.png";
import exchange from "../../assets/arts/airdrop/exchange logo_green 8.png";
import wallet from "../../assets/arts/airdrop/wallet black 1.png";
import metamask from "../../assets/arts/airdrop/metamask-logo 1.png";
import pile1 from "../../assets/arts/airdrop/iusd1.png";
import pile2 from "../../assets/arts/airdrop/iusd2.png";
import pile3 from "../../assets/arts/airdrop/iusd3.png";
import pile4 from "../../assets/arts/airdrop/iusd4.png";
import FormControl from "@mui/material/FormControl";
import "./RegisterAirDrop.css";
import { useNavigate } from "react-router-dom";

const RegisterAirDropMobile = () => {
  const [selectedValue, setSelectedValue] = useState("hive");

  const navigate = useNavigate();
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // const handleClick = () => {
  //   if (selectedValue === "metamask") navigate("/airdrop-register-mask");
  //   else if (selectedValue === "hive") navigate("/airdrop-register-hive");
  //   else if (selectedValue === "exchange") navigate("/airdrop-register-exch");
  //   else if (selectedValue === "wallet") navigate("/airdrop-register-wall");
  // };

  const handleClick = () => {
    if (selectedValue === "hive") navigate("/airdrop-register-wis-hive");
    else if (selectedValue === "exchange")
      navigate("/airdrop-register-wis-exch");
    else if (selectedValue === "wallet") navigate("/airdrop-register-wis-wall");
  };

  return (
    <div style={{ paddingTop: "120px" }}>
      <div className="d-flex flex-column justify-content-center align-items-center font_42x mb-5 fw-bold">
        <div style={{ width: "84%", textAlign: "center" }}>
          Select platform
          <div className="font_25x">to take part in airdrop</div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <FormControl className=" mt-5">
          <span className="font_15x">Select platform*</span>
          <Select
            labelId="payment-method-label"
            id="payment-method"
            value={selectedValue}
            onChange={handleSelectChange}
            className="select-input"
          >
            <MenuItem value="hive">
              <img alt="Female" src={hive_exch} className="drop-image" />
              Hive Exchange
            </MenuItem>
            <MenuItem value="exchange">
              <img alt="Female" src={exchange} className="drop-image" />
              Exchange
            </MenuItem>
            <MenuItem value="wallet">
              <img alt="Female" src={wallet} className="drop-image" />
              Indexx Web 3 Wallet
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center pb-5">
        <div className="img-pile text-center">
          {/* {selectedValue === "hive" && <img alt="Female" src={pile4} />}
          {selectedValue === "exchange" && <img alt="Female" src={pile3} />}
          {selectedValue === "wallet" && <img alt="Female" src={pile2} />}
          {selectedValue === "metamask" && <img alt="Female" src={pile1} />} */}

          <div className="font_18x fw-bold mt-3">
            {selectedValue === "hive" && "$15 ~ 75,000 WIBS"}
            {selectedValue === "exchange" && "$10 ~ 50,000 WIBS"}
            {selectedValue === "wallet" && "$10 ~ 50,000 WIBS"}
            <br />
          </div>
        </div>
        <Button
          variant="contained"
          disableTouchRipple
          onClick={handleClick}
          sx={{
            backgroundColor: "#F59219",
            borderRadius: "2px",
            // color: "#282828",
            width: "84%",
            px: 10,
            py: 1,
            textTransform: "none",
            fontSize: "13px",
            fontWeight: 700,
            boxShadow: "none",
            mt: 5,
            mb: 10,
            height: "55px",
            "&:hover": {
              backgroundColor: "#F59219",
              boxShadow: "none",
            },
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default RegisterAirDropMobile;
