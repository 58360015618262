import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import algorand from "../../assets/arts/airdrop/wallets/algorand.png";
import arget from "../../assets/arts/airdrop/wallets/arget.png";
import avalance from "../../assets/arts/airdrop/wallets/avalance.png";
import fantom from "../../assets/arts/airdrop/wallets/fantom.png";
import firefly from "../../assets/arts/airdrop/wallets/firefly.png";
import keplr from "../../assets/arts/airdrop/wallets/keplr.png";
import metamask from "../../assets/arts/airdrop/wallets/metamask-logo 1.png";
import near from "../../assets/arts/airdrop/wallets/near.png";
import near_dark from "../../assets/arts/airdrop/wallets/near_dark.png";
import phantom from "../../assets/arts/airdrop/wallets/phantom.png";
import polkadot from "../../assets/arts/airdrop/wallets/polkadot.png";
import terra from "../../assets/arts/airdrop/wallets/terra.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./RegisterAirDrop.css";
import { airdropRegister } from "../../services/api";
import { ethers } from "ethers";
import ChooseAirDropMobile from "./ChooseAirDropMobile";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ChooseAirDrop = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const [selectedValue, setSelectedValue] = useState("metamask");
  const [isValidCapcha, setIsValidCapcha] = useState(false);
  const [walletAdd, setWalletAdd] = useState("");
  const [isWalletAddValid, setIsWalletAddValid] = useState(true);
  const airdropAmount = 5;
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  function onChange(value) {
    if (value) {
      setIsValidCapcha(true);
    }
  }

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleRadioChange = (event) => {
    console.log(selectedValue, "selval");
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async () => {
    let register = await airdropRegister(
      undefined,
      "External",
      walletAdd,
      selectedValue,
      airdropAmount,
      "IUSD+",
      "SaintPatrick"
    );
    console.log(register);
    alert(register.data.message);
  };

  const handleWalletAddressChange = (e) => {
    const address = e.target.value;
    setWalletAdd(address);
    const isValid = isValidEthereumAddress(address);
    setIsWalletAddValid(isValid);
  };

  const isValidEthereumAddress = (address) => {
    try {
      return ethers.utils.isAddress(address);
    } catch (e) {
      return false;
    }
  };

  const renderPaymentOption = (value, imageSrc, imageSrcdark, description) => (
    <FormControlLabel
      key={value}
      value={value}
      control={
        <Radio
          icon={<div style={{ display: "none" }} />}
          checkedIcon={<div style={{ display: "none" }} />}
        />
      }
      labelPlacement="start"
      label={
        <div className="label-content">
          <div className="img-contain">
            <img
              src={theme === "dark" ? imageSrcdark || imageSrc : imageSrc}
              alt={description}
              className={`pay-image ${
                selectedValue === value ? "selected-opt" : ""
              }`}
            />
          </div>
          <p className="pay-description">{description}</p>
        </div>
      }
    />
  );

  const paymentOptions = [
    { value: "metamask", imageSrc: metamask, description: "Metamask" },
    { value: "arget", imageSrc: arget, description: "Arget" },
    { value: "keplr", imageSrc: keplr, description: "Keplr Wallet" },
    { value: "phantom", imageSrc: phantom, description: "Phantom Wallet" },
    { value: "terra", imageSrc: terra, description: "Terra Station" },
    { value: "polkadot", imageSrc: polkadot, description: "Polkadot Wallet" },
    { value: "avalance", imageSrc: avalance, description: "Avalance Wallet" },
    {
      value: "near",
      imageSrc: near,
      imageSrcdark: near_dark,
      description: "Near Wallet",
    },
    { value: "algorand", imageSrc: algorand, description: "Algorand Wallet" },
    { value: "fantom", imageSrc: fantom, description: "Fantom Wallet" },
    { value: "firefly", imageSrc: firefly, description: "Firefly iota Wallet" },
  ];

  return (
    <>
      {isMobile ? (
        <ChooseAirDropMobile />
      ) : (
        <div style={{ paddingTop: "180px" }}>
          <div className="d-flex justify-content-center font_50x fw-bold mb-5">
            Airdrop Registration
          </div>
          <div className="d-flex justify-content-center mb-5">
            <FormControl component="fieldset">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                onChange={handleRadioChange}
                name="radio-buttons-group"
                className="radio-group"
                sx={{
                  gap: 4,
                  width: "55%",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                {paymentOptions.map((option) =>
                  renderPaymentOption(
                    option.value,
                    option.imageSrc,
                    option.imageSrcdark,
                    option.description
                  )
                )}
              </RadioGroup>
            </FormControl>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center mt-5 pb-5">
            <Box width={"50%"} className="d-flex align-items-center mb-5">
              <Typography
                variant="text"
                fontSize={"15px"}
                fontWeight={500}
                textAlign={"left"}
                mr={3}
              >
                Wallet Address
              </Typography>

              <TextField
                label=""
                placeholder="Wallet Address"
                variant="outlined"
                sx={{ width: "70%" }}
                size="small" // Make the input box smaller
                value={walletAdd}
                onChange={handleWalletAddressChange}
              />
            </Box>
            {/* <div style={{ border: "1px solid red" }}>
              <ReCAPTCHA
                sitekey="6LcQZ2cpAAAAAKCRKBNdF_uhH2YP-HzlGfqHSf3Z"
                onChange={onChange}
              />
            </div> */}

            <GoogleReCaptchaProvider reCaptchaKey="6Lc_TmcpAAAAAN1dCAhWpCL6KjCC3LAboAfTrLjk">
              <GoogleReCaptcha onVerify={onChange} />
            </GoogleReCaptchaProvider>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disableTouchRipple
              disabled={!isValidEthereumAddress(walletAdd) && !isValidCapcha}
              sx={{
                backgroundColor: "#0078E5",
                borderRadius: "2px",
                // color: "#282828",
                width: "316px",
                px: 10,
                py: 1,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 700,
                boxShadow: "none",
                // mt: 3,
                mb: 10,
                height: "40px",
                "&:hover": {
                  backgroundColor: "#0048B4",
                  boxShadow: "none",
                },
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseAirDrop;
