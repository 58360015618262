import { useTheme } from "@mui/material/styles";
import React from "react";
import LoginComponent from "../../../components/updated/authentication/loginEmail";
import SignUpEmail from "../../../components/updated/authentication/signup/SignupEmail";
import { useLocation } from "react-router-dom";

const SignUpEmailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let redirectWebsiteLink = searchParams.get('redirectWebsiteLink');
  let referralCode = searchParams.get('referralCode');

  const theme = useTheme();
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme?.palette?.background.default,
        color: theme?.palette?.text?.primary,
      }}
    >
      <SignUpEmail redirectWebsiteLink={redirectWebsiteLink} referralCode={referralCode}/>
    </div>
  );
};

export default SignUpEmailPage;
