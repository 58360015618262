import bit100 from "../../assets/digitalproducts/bit-gift/BTC 100_3 copy 1.svg";
import bit200 from "../../assets/digitalproducts/bit-gift/200_1 1.svg";
import bit300 from "../../assets/digitalproducts/bit-gift/300_2 1.svg";
import bit500 from "../../assets/digitalproducts/bit-gift/500_2 1.svg";
import bit1000 from "../../assets/digitalproducts/bit-gift/1000_4 1.svg";

import eth100 from "../../assets/digitalproducts/eth-gift/100_3 1.svg";
import eth200 from "../../assets/digitalproducts/eth-gift/200_3 1.svg";
import eth300 from "../../assets/digitalproducts/eth-gift/300_1 1.svg";
import eth500 from "../../assets/digitalproducts/eth-gift/500_1 1.svg";
import eth1000 from "../../assets/digitalproducts/eth-gift/1000_2 1.svg";

import bitblack from "../../assets/digitalproducts/bit-xnft/bitblack.svg";
import bitblue from "../../assets/digitalproducts/bit-xnft/bitblue.svg";
import bitgreen from "../../assets/digitalproducts/bit-xnft/bitgreen.svg";
import bitorange from "../../assets/digitalproducts/bit-xnft/bitorange.svg";
import bitpink from "../../assets/digitalproducts/bit-xnft/bitpink.svg";
import bitpurple from "../../assets/digitalproducts/bit-xnft/bitpurple.svg";
import bitdollar from "../../assets/digitalproducts/bit-xnft/up1rhohd 1.svg";


import ethblack from "../../assets/digitalproducts/eth-xnft/eth-black.svg";
import ethblue from "../../assets/digitalproducts/eth-xnft/eth-blue.svg";
import ethgreen from "../../assets/digitalproducts/eth-xnft/eth-green.svg";
import ethorange from "../../assets/digitalproducts/eth-xnft/eth-orange.svg";
import ethpink from "../../assets/digitalproducts/eth-xnft/eth-pink.svg";
import ethpurple from "../../assets/digitalproducts/eth-xnft/eth-purple.svg";

import bg1 from "../../assets/digitalproducts/bit-greet-cards/G-1.svg";
import bg2 from "../../assets/digitalproducts/bit-greet-cards/G-2.svg";
import bg3 from "../../assets/digitalproducts/bit-greet-cards/G-3.svg";
import bg4 from "../../assets/digitalproducts/bit-greet-cards/G-4.svg";

import eg1 from "../../assets/digitalproducts/eth-greet-cards/G-1.svg";
import eg2 from "../../assets/digitalproducts/eth-greet-cards/G-2.svg";
import eg3 from "../../assets/digitalproducts/eth-greet-cards/G-3.svg";
import eg4 from "../../assets/digitalproducts/eth-greet-cards/G-4.svg";

import xusd1 from "../../assets/digitalproducts/xusd/XUSD_01 (1) 1.svg";
import xusd2 from "../../assets/digitalproducts/xusd/100 usd layout_02 1.svg";


export const bitgiftcard = [
    {
      id:"1",
      image:bit100,
      amount:"$100"
    },
    {
      id:"2",
      image:bit200,
      amount:"$200"
    },
    {
      id:"3",
      image:bit300,
      amount:"$300"
    },
    {
      id:"4",
      image:bit500,
      amount:"$500"
    },
    {
      id:"5",
      image:bit1000,
      amount:"$1000"
    }
  ]

 export const ethgiftcard = [
    {
      id:"1",
      image:eth100,
      amount:"$100"
    },
    {
      id:"2",
      image:eth200,
      amount:"$200"
    },
    {
      id:"3",
      image:eth300,
      amount:"$300"
    },
    {
      id:"4",
      image:eth500,
      amount:"$500"
    },
    {
      id:"5",
      image:eth1000,
      amount:"$1000"
    }
  ]

export const bitxnft = [
  {
    id:"1",
    image:bitblack,
    name:"Bitcoin"
  },
  {
    id:"2",
    image:bitpurple,
    name:"Bitcoin Purple"
  },
  {
    id:"3",
    image:bitblue,
    name:"Bitcoin Blue"
  },
  {
    id:"4",
    image:bitgreen,
    name:"Bitcoin Green"
  },
  {
    id:"5",
    image:bitpink,
    name:"Bitcoin Pink"
  },
  {
    id:"6",
    image:bitorange,
    name:"Bitcoin Orange"
  },
  {
    id:"7",
    image:bitdollar,
    name:"1$ Bitcoin XNFT"
  }
]

export const ethxnft = [
  {
    id:"1",
    image:ethblack,
    name:"Ethereum"
  },
  {
    id:"2",
    image:ethpurple,
    name:"Ethereum Purple"
  },
  {
    id:"3",
    image:ethblue,
    name:"Ethereum Blue"
  },
  {
    id:"4",
    image:ethgreen,
    name:"Ethereum Green"
  },
  {
    id:"5",
    image:ethpink,
    name:"Ethereum Pink"
  },
  {
    id:"6",
    image:ethorange,
    name:"Ethereum Orange"
  }
]

export const bitgreetcard = [
  {
    id:"1",
    image:bg1,
  },
  {
    id:"2",
    image:bg2,
  },
  {
    id:"3",
    image:bg3,
  },
  {
    id:"4",
    image:bg4,
  }
]

export const ethgreetcard = [
  {
    id:"1",
    image:eg1,
  },
  {
    id:"2",
    image:eg2,
  },
  {
    id:"3",
    image:eg3,
  },
  {
    id:"4",
    image:eg4,
  }
]

export const xusd = [
  {
    id:"1",
    image:xusd1,
  },
  {
    id:"2",
    image:xusd2,
  }
]