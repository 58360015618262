import React from "react";
import { Link } from "react-router-dom";
import memeCoin from "../../../assets/meme/meme coin.png";

import soRekt from "../../../assets/meme/sorekt.png";
import coin from "../../../assets/meme/wis.png";
import "./style.css"; // import your CSS file for styling
import IconicHeader from "./IconicHeader";
import Footer from "../../Footer/Footer";

const CoinInner = () => {
  return (
    <div>
      <IconicHeader type="meme" />
      <section
        style={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          margin: "50px auto 0px auto",
          gap: "15px",
        }}
      >
        <div>
          <img src={memeCoin} height="100px" />
        </div>
        <p style={{ fontSize: "40px", fontWeight: "bold" }}>
          Meme Coin Projects
        </p>
      </section>
      <section className="project-section">
        {/* <h1 className="main-heading">Projects</h1> */}
        <div className="project-container">
          {/* Project Cards */}
          <div className="project-card">
            <a href="https://test.whoisbitcoinsatoshi.wtf/coin" target="_blank">
              <img src={coin} alt="Project 1" />
            </a>
            <div>
              <h2>Bitcoin Satoshi Coin</h2>
              <p>
                "Who Is Satoshi Coin (WIBS): A digital enigma priced at $0.00021,
                with 210 Billion tokens, upholding Satoshi's vision through
                anonymity."
              </p>
            </div>
            <a
              href="https://test.whoisbitcoinsatoshi.wtf/coin"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="/sorekt-coming-soon">
              <img src={soRekt} alt="Project 1" />
            </a>
            <div>
              <h2>SoRekt</h2>
              <p>
                Introducing SoRekt Meme Coin: Uniting a community through humor
                and resilience in the face of market challenges. Join us on this
                crypto journey!
              </p>
            </div>
            <a
              href="/sorekt-coming-soon"
              className="learn-more-button"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          {/* Repeat the above project card structure for other projects */}
        </div>
      </section>
      <Footer helpIcon={false} />
    </div>
  );
};

export default CoinInner;
