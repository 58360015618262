import { Box, Button, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import hive_exch from "../../assets/arts/airdrop/indexx exchange logo new 1.png";
import exchange from "../../assets/arts/airdrop/exchange logo_green 8.png";
import wallet from "../../assets/arts/airdrop/wallet black 1.png";
import metamask from "../../assets/arts/airdrop/metamask-logo 1.png";
import pile from "../../assets/arts/airdrop/pile 1 1.png";
import FormControl from "@mui/material/FormControl";
import "./RegisterAirDrop.css";
import { useNavigate } from "react-router-dom";

const RegisterAirDropMobile = () => {

  const [selectedValue, setSelectedValue] = useState("hive");

  const navigate = useNavigate();
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClick = () => {
    if(selectedValue === 'metamask')
      navigate('/airdrop-register-mask');
    else if(selectedValue === 'hive')
      navigate('/airdrop-register-hive');
    else if(selectedValue === 'exchange')
      navigate('/airdrop-register-exch');
    else if(selectedValue === 'wallet')
      navigate('/airdrop-register-wall');
  }

  return (
    <div style={{ paddingTop: "120px" }}>

      <div className="d-flex flex-column justify-content-center align-items-center font_42x mb-5 fw-bold">
      <div style={{width:"84%"}}> 
        Select platform 
        <div className="font_25x">
        to take part in airdrop
        </div>
      </div>
      </div>
      <div className="d-flex justify-content-center">
      <FormControl className=" mt-5">
      <span className="font_15x">

      Select platform* 
      </span>
      <Select
        labelId="payment-method-label"
        id="payment-method"
        value={selectedValue}
        onChange={handleSelectChange}
        className="select-input"
      >
        <MenuItem value="hive">
        <img
                      alt="Female"
                      src={hive_exch}
                      className="drop-image"
                    />
          Hive Exchange
        </MenuItem>
        <MenuItem value="exchange">
        <img
                      alt="Female"
                      src={exchange}
                      className="drop-image"
                    />
          Exchange
        </MenuItem>
        <MenuItem value="wallet">
        <img
                      alt="Female"
                      src={wallet}
                      className="drop-image"
                    />
          Indexx Web 3 Wallet
        </MenuItem>
        <MenuItem value="metamask">
        <img
                      alt="Female"
                      src={metamask}
                      className="drop-image"
                    />
          Metamask or Decentralized Wallets
        </MenuItem>
      </Select>
    </FormControl>
        </div>

        <div className="d-flex flex-column align-items-center justify-content-center pb-5">
        <div className="img-pile text-center">
                    <img alt="Female" src={pile} />
                    <div className="font_18x fw-bold mt-3">
                    {selectedValue === 'hive' && "$90 ~ 45 INEX"}
                    {selectedValue === 'exchange' && "$60 ~ 30 INEX"}
                    {selectedValue === 'wallet' && "$60 ~ 30 INEX"}
                    {selectedValue === 'metamask' && "$10 ~ 5 INEX"}
                    <br />
                    INEX Bonus
                    </div>
                  </div>
        <Button variant='contained'
              disableTouchRipple
              disabled={true}
              onClick={handleClick}
                sx={{
                    backgroundColor: "#0078E5",
                    borderRadius: "2px",
                    // color: "#282828",
                    width: "84%",
                    px: 10,
                    py: 1,
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: 700,
                    boxShadow: "none",
                    mt: 5,
                    mb:10,
                    height: "55px",
                    "&:hover": {
                        backgroundColor: "#0048B4",
                        boxShadow: "none",
                    },
                }}
            >
                Confirm
            </Button>
        </div>
    </div>
  );
};

export default RegisterAirDropMobile;
