import React, { useState, createContext, useEffect } from "react";

interface IPopupContext {
  popup: boolean;
  setPopup: any;
}
export const Popup = createContext<IPopupContext | null>(null);

export const PopupContext = ({ children }: any) => {
  const [popup, setPopup] = useState<boolean>(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      localStorage.removeItem("popupVisible");
      console.log("in settimeout");
      const expirationTime = localStorage.getItem("expirationTime");
      console.log(expirationTime, "expiration");
      if (!expirationTime) setPopup(true);
      if (expirationTime && currentTime >= parseInt(expirationTime)) {
        console.log(currentTime >= parseInt(expirationTime ?? ""), "checking");

        setPopup(true);
      }
    }, 6000); // Check every minute

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Popup.Provider value={{ popup, setPopup }}>{children}</Popup.Provider>
  );
};
