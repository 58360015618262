import React, { useState } from "react";
import data from "./data";
import DiscountCard from "./DiscountCard";
import "./style.css";
import DiscountHero from "./DiscountHero";
import DiscountTabs from "./DiscountTabs";
function DiscountShop() {
  const [activeTab, setActiveTab] = useState("carnival");

  const colorFilter = {
    heroBgColor:
      activeTab === "carnival"
        ? "#FFC3EE"
        : activeTab === "bitcoin"
        ? "#FEDDB3"
        : activeTab === "inex"
        ? "#CCF0FF"
        : activeTab === "power"
        ? "#FFE8B3"
        : activeTab === "bonus"
        ? "#B9E3CF"
        : "none",
    textColor:
      activeTab === "carnival"
        ? "#880062"
        : activeTab === "bitcoin"
        ? "#B74200"
        : activeTab === "inex"
        ? "#002890"
        : activeTab === "power"
        ? "#DF6B00"
        : activeTab === "bonus"
        ? "#006C39"
        : "none",
  };
  return (
    <>
      {/*  Initial Gap */}
      <div style={{ marginTop: "170px" }}></div>
      {/* Hero Section Image  */}
      <DiscountHero
        bgColor={colorFilter.heroBgColor}
        textColor={colorFilter.textColor}
      />
      {/* Discount Tabs */}
      <DiscountTabs setActiveTab={setActiveTab} />
      {/* Discount Cards */}
      <p
        style={{
          maxWidth: "1000px",
          margin: "50px auto",
          textAlign: "center",
          fontSize: "16px",
          color: "#000",
        }}
      >
        Purchase Crypto and enjoy up to 90% discount on Indexx Tokens
      </p>
      <section className="all-cards-styling">
        {data
          .filter((el) => el.type === activeTab)
          .map((discountCard) => (
            <DiscountCard {...discountCard} activeTab={activeTab} />
          ))}
      </section>
    </>
  );
}

export default DiscountShop;
