import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import hive_exch from "../../assets/arts/airdrop/indexx exchange logo new 1.png";
import exchange from "../../assets/arts/airdrop/exchange logo_green 8.png";
import wallet from "../../assets/arts/airdrop/wallet black 1.png";
import metamask from "../../assets/arts/airdrop/metamask-logo 1.png";
import pile from "../../assets/arts/airdrop/pile 1 1.png";
import pile2 from "../../assets/arts/airdrop/pile 2 1.png";
import pile3 from "../../assets/arts/airdrop/pile 3 1.png";
import pile4 from "../../assets/arts/airdrop/pile 4 1.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./RegisterAirDrop.css";
import { useNavigate } from "react-router-dom";
import RegisterAirDropMobile from "./RegisterAirDropMobile";

const RegisterAirDrop = () => {
  const isMobile = useMediaQuery('(max-width:768px)');

  const [selectedValue, setSelectedValue] = useState("hive");

  const navigate = useNavigate();
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClick = () => {
    if (selectedValue === 'metamask')
      navigate('/airdrop-register-mask');
    else if (selectedValue === 'hive')
      navigate('/airdrop-register-hive');
    else if (selectedValue === 'exchange')
      navigate('/airdrop-register-exch');
    else if (selectedValue === 'wallet')
      navigate('/airdrop-register-wall');
  }
  const optionInfo = {
    hive: {
      title: 'Hive Exchange',
      amount: '$40 ~ 20 INEX',
      summary: 'Registering on Hive Exchange ensures the highest allocation of airdropped tokens. Users have the option to sign up as Captain Bees, enjoying unique privileges like forming colonies, earning commissions, and guiding fellow community members. This choice maximizes airdrop potential and is perfect for those aiming for a leadership role within the Indexx community.',
      learnMoreLink: "https://hive.indexx.ai",
      pileImage: pile,
    },
    exchange: {
      title: 'Exchange',
      amount: '$30 ~ 15 INEX',
      summary: 'For a substantial allocation of airdropped tokens, Normal Exchange registration is the second-best choice. Users can easily convert to Captain Bees later if desired. This option caters to those who prefer a straightforward exchange experience for converting, buying, and selling various cryptocurrencies and Wall Street assets.',
      learnMoreLink: "https://cex.indexx.ai",
      pileImage: pile2,
    },
    wallet: {
      title: 'Indexx Wallet',
      amount: '$30 ~ 15 INEX',
      summary: 'Choosing Indexx Wallet provides a secure storage solution with the third-highest allocation of airdropped tokens. Users can register in two different ways, benefiting from a hybrid wallet (DEX and CEX). Beyond secure storage, the wallet offers features like mining, an NFT wallet, and a bridge for cross-chain transactions, making it ideal for those seeking a comprehensive crypto wallet experience.',
      learnMoreLink: "https://wallet.indexx.ai/",
      pileImage: pile3,
    },
    metamask: {
      title: 'Metamask/DEX Wallet',
      amount: '$10 ~ 5 INEX',
      summary: 'While convenient, Metamask/DEX Wallet registration offers the least airdropped tokens. Users entering their address will receive tokens, but to utilize them fully, eventual registration on the Indexx Exchange is required. For a more integrated experience within the Indexx ecosystem, users are encouraged to consider the first two options, ensuring a seamless transition for using airdropped tokens on the exchange.',
      learnMoreLink: "https://metamask.io/",
      pileImage: pile4,
    },
  };
  

  return (
    <>
      {isMobile ?
        <RegisterAirDropMobile />
        :
        <div style={{ paddingTop: "180px",textAlign:'center' }}>
          <div className="d-flex justify-content-center font_30x mb-5 fw-bold">
            Select platform to take part in airdrop
          </div>

          <div className="d-flex flex-column align-items-center mt-4 ">
            <h1 className="font_100x fw-bold">
              {optionInfo[selectedValue].amount}  <img src={optionInfo[selectedValue].pileImage} width={120} style={{marginTop:-30}} alt="inex"></img>
            </h1>
            </div>
            <div className="d-flex justify-content-center">
            <FormControl className="pay-form mt-5" sx={{ display: 'flex', alignItems: 'center' }}>

<div className="row">
<div className="col-md-4">
  <FormLabel
    className="select-text"
    id="demo-radio-buttons-group-label"
    style={{marginBottom:-40}}
  >
    Select Platform:
  </FormLabel>
</div>

{/* Dropdown menu for platform selection */}
<div className="col-md-8">
<Select
  value={selectedValue}
  onChange={(event) => setSelectedValue(event.target.value)}
  className="dropdown"
  sx={{ width: '306px', marginLeft: '10px', display: 'flex', alignItems: 'center' }}
>
  <MenuItem
    value="hive"
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
  >
    <img src={hive_exch} alt="Hive Exchange" className="select-image" style={{ marginRight: '10px' }} />
    Hive Exchange
  </MenuItem>
  <MenuItem
    value="exchange"
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
  >
    <img src={exchange} alt="Exchange" className="select-image" style={{ marginRight: '10px' }} />
    Exchange
  </MenuItem>
  <MenuItem
    value="wallet"
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
  >
    <img src={wallet} alt="Indexx Wallet" className="select-image" style={{ marginRight: '10px' }} />
    Indexx Wallet
  </MenuItem>
  <MenuItem
    value="metamask"
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
  >
    <img src={metamask} alt="Metamask/DEX Wallet" className="select-image" style={{ marginRight: '10px' }} />
    Metamask/DEX Wallet
  </MenuItem>
</Select>

</div>

</div>

</FormControl>

</div>


          {/* Display information based on selected option */}
          <div className="d-flex flex-column align-items-center mt-4">
          <p className="font_16x" style={{ maxWidth: "600px", textAlign: "left", margin: "0 auto" }}>
  {optionInfo[selectedValue].summary}
</p>

<br></br>

        
<a href={optionInfo[selectedValue].learnMoreLink} target="_blank" rel="noopener noreferrer">
    Learn More
  </a>
          </div>

          <div className="d-flex justify-content-center pb-5">
            <Button variant='contained'
             disabled={true}
              disableTouchRipple
              onClick={handleClick}
              sx={{
                backgroundColor: "#0078E5",
                borderRadius: "2px",
                width: "316px",
                px: 10,
                py: 1,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 700,
                boxShadow: "none",
                mt: 5,
                mb: 10,
                height: "40px",
                "&:hover": {
                  backgroundColor: "#0048B4",
                  boxShadow: "none",
                },
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      }
    </>
  );
};

export default RegisterAirDrop;
