import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Typography } from "antd";
import { ethgiftcard } from './AllData';
import { useEffect, useState } from "react";
import text from "../../assets/digitalproducts/eth-gift/eth-text.svg"
import text_black from "../../assets/digitalproducts/eth-gift/eth-text-black.svg"
import './MainPage.css'
import Footer from '../Footer/Footer';

import gift from "../../assets/digitalproducts/coverpage/gift-03.svg";
import gift_white from "../../assets/digitalproducts/coverpage/gift card_icon 1.svg";

import xnft from "../../assets/digitalproducts/coverpage/xnft icon_3 1.svg";
import xnft_white from "../../assets/digitalproducts/coverpage/xnft icon_2 1.svg";

import greet from "../../assets/digitalproducts/coverpage/greeting card icon 1.svg";
import dollar from "../../assets/digitalproducts/coverpage/dollar icon 1.svg";


const { Text } = Typography;

const EthereumGC = () => {
  
    const { id } = useParams();

    const filteredimg = ethgiftcard.filter((sd) => sd.id === id);
    console.log(filteredimg);
    
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || "dark"
  );


  useEffect(() => {
    const handleStorageChange = (event:any) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('resize', handleWindowSizeChange);
    
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  console.log(theme);
  
  const isMobile = width <= 768;
  
    return (
<>

    <div className='scan-container flex-direction-column'>
    <div className="container cover-page" style={{marginBottom:"50px"}}>

      <div className="row row-cols-1 row-cols-md-4 g-4 justify-center up-logos">
        
          <div className="col">
            <Link to="/digital-products-info/#bitcoin">
              <div className="card">
              {theme === "dark" ? 
              <img src={gift} className="card-img-top" alt="..."/>
              :
              <img src={gift_white} className="card-img-top" alt="..."/>
              }
              <div className="card-body">
                  <h5 className="card-title">Gift Card</h5>
              </div>
              </div>
            </Link>
          </div>
          <div className="col">
          <a href="/digital-products-info/#xnft">
              <div className="card">
              {theme === "dark" ? 
              <img src={xnft} className="card-img-top" alt="..."/>
              :
              <img src={xnft_white} className="card-img-top" alt="..."/>
              }
              <div className="card-body">
                  <h5 className="card-title">XNFT</h5>
              </div>
              </div>
            </a>
          </div>
          <div className="col">
          <a href="/digital-products-info/#greet-cards">
              <div className="card">
              <img src={greet} className="card-img-top" alt="..."/>
              <div className="card-body">
                  <h5 className="card-title">Greeting Card</h5>
              </div>
              </div>
            </a>
          </div>
          <div className="col">
          <a href="/digital-products-info/#xusd-sec">
              <div className="card">
              <img src={dollar} className="card-img-top" alt="..."/>
              <div className="card-body">
                  <h5 className="card-title">XUSD</h5>
              </div>
              </div>
          </a>
          </div>
      </div>
    </div>
    <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "50px"}`,
          }}
        >
    Ethereum XNFT Gift Card
        </Text>

    <img src={filteredimg[0].image} alt="bitcoincard" style={{margin:"30px 0 50px"}} />
    {theme === "dark" ? 
    <img alt="" src={text_black} />
    :  
    <img alt="" src={text} />
  }

        <div style={{marginTop:"60px"}} className='main-page'>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/collections/ethereum-xnft">Buy Gift Card</Link></Button>
        </div>
    </div>
    <Footer></Footer>
</>

  )
}

export default EthereumGC;