import React from 'react'
import Footer from '../Footer/Footer';
import comingsoon from '../../assets/arts/nfts/coming_naka.png';

const ComingSoonNakaMoto = () => {
    return (
        <>
            <div className='' style={{paddingTop : "90px"}}>
                
                    <img src={comingsoon} alt="Coming soon art" width={"100%"}/>
                
            </div>

            <Footer />
        </>
    )
}

export default ComingSoonNakaMoto