import React from "react";
// import telephone from "../../../assets/arts/telephone 1.svg";
import {
  Box,
  Button,
  Typography,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

const ContactUs = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const [name, setName] = useState("");
  const [Email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState("");

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email);
    setEmailError(isValid ? "" : "Invalid email address");
    return isValid;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: `${isMobile ? "column" : "row"}`,
        justifyContent: `${isMobile ? "center" : "space-between"}`,
        alignItems: "center",
        width: `${isMobile ? "100%" : "80%"}`,
        mx: "auto",
        my: 25,
        maxWidth: "1520px",
      }}
    >
      <Box
        width={isMobile ? "100%" : "45%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: `${isMobile ? "center" : "flex-start"}`,
          justifyContent: `${isMobile ? "center" : "space-between"}`,
          color: "var(--dark_text)",
        }}
      >
        {/* <Box
                component="img"
                sx={{
                    width: `${isMobile ? "20%" : "auto"}`,
                    // width: "100%",
                    // mb:4
                }}
                alt=""
                src={telephone}
            /> */}
        <Typography
          variant="text"
          fontSize={isMobile ? "45px" : "70px"}
          fontWeight={isMobile ? 600 : 500}
          textAlign={"left"}
        >
          Connect with us
        </Typography>

        <Typography
          fontSize={isMobile ? "16px" : "13px"}
          // fontFamily={"Inter"}
          fontWeight={400}
          lineHeight={"28px"}
          textAlign={"left"}
          mb={isMobile ? 4 : 0}
        >
          Be a part of our vibrant community! Stay updated on the latest news,
          exclusive content, and exciting events. Join the conversation on our
          social media platforms, engage with fellow enthusiasts, and explore
          the world with us. Your connection matters, and we can't wait to share
          the journey together!
        </Typography>
      </Box>
      <Box width={isMobile ? "100%" : "45%"}>
        <TextField
          label="Name"
          variant="outlined"
          sx={{
            mb: `${isMobile ? "30px" : "24px"}`,
            width: "100%",
            color: "var(--body_color)",
          }}
          size="small" // Make the input box smaller
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          label="Email"
          variant="outlined"
          sx={{ mb: `${isMobile ? "30px" : "24px"}`, width: "100%" }}
          size="small" // Make the input box smaller
          type="email"
          value={Email}
          error={emailError !== ""}
          helperText={emailError}
          onBlur={validateEmail}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        {/* <TextField
                label="Message"
                variant="outlined"
                sx={{ mb: 2, width: '100%' }}
                size="small" // Make the input box smaller
                value={message}
                onChange={(e) => {
                    setMessage(e.target.value);
                }}
            /> */}
        <Button
          variant="contained"
          disableTouchRipple
          sx={{
            backgroundColor: "#F59219",
            borderRadius: "2px",
            // color: "#282828",
            width: `${isMobile ? "100%" : "316px"}`,
            px: 10,
            py: 1,
            textTransform: "none",
            fontSize: "13px",
            fontWeight: 700,
            boxShadow: "none",
            // mt: 3,
            height: `${isMobile ? "55px" : "40px"}`,
            "&:hover": {
              backgroundColor: "#FFB300",
              boxShadow: "none",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUs;
