import { ThemeProvider, createTheme } from "@mui/material";
import React, { useState, createContext } from "react";

export const Theme = createContext();

export const ThemeContext = ({ children }) => {
  const selectedTheme = localStorage.getItem("selectedTheme");
  const [theme, setTheme] = useState(selectedTheme ?? "dark");
  const [mode, setMode] = React.useState(selectedTheme ?? "dark");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const themes = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                primary: {
                  main: "#11BE6A",
                },
                divider: "#EAECEF",
                border: "#EAECEF",
                background: {
                  default: "#FCFCFC",
                },
                text: {
                  primary: "#1E2329",
                  secondary: "#474D57",
                },
              }
            : {
                // palette values for dark mode
                primary: {
                  main: "#11BE6A",
                },
                divider: "#2B3139",
                border: "#474D57",
                background: {
                  default: "#181A21",
                },
                text: {
                  primary: "#EAECEF",
                  secondary: "#B7BDC6",
                },
              }),
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                background: mode === "light" ? "#FCFCFC" : "#181A21",
              },

              "::-webkit-scrollbar": {
                width: "8px",
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#B7BDC6",
                borderRadius: "4px",
              },
              "::-webkit-scrollbar-track": {
                display: "none", // Hide the scrollbar track
              },

              "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#B7BDC6 !important", // Keep the same color on hover
              },
            },
          },
        },
      }),
    [mode]
  );
  return (
    <Theme.Provider value={{ theme, setTheme, colorMode }}>
      <ThemeProvider theme={themes}>{children}</ThemeProvider>
    </Theme.Provider>
  );
};
