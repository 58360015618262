import btc from "../../assets/discountShop/bitcoin.svg";
import eth from "../../assets/discountShop/eth.svg";
import doge from "../../assets/discountShop/doge.svg";
import satoshi from "../../assets/discountShop/satoshi.svg";

import btc1 from "../../assets/discountShop/bit1.svg";
import btc2 from "../../assets/discountShop/bit2.svg";
import btc3 from "../../assets/discountShop/bit3.svg";
import btc4 from "../../assets/discountShop/bit4.svg";
import btc5 from "../../assets/discountShop/bit5.svg";
import btc6 from "../../assets/discountShop/bit6.svg";
import btc7 from "../../assets/discountShop/bit7.svg";
import btc8 from "../../assets/discountShop/bit8.svg";

import inex1 from "../../assets/discountShop/inex1.svg";
import inex2 from "../../assets/discountShop/inex2.svg";
import inex3 from "../../assets/discountShop/inex3.svg";
import inex4 from "../../assets/discountShop/inex4.svg";

import power1 from "../../assets/discountShop/power1.svg";
import power2 from "../../assets/discountShop/power2.svg";
import power3 from "../../assets/discountShop/power3.svg";
import power4 from "../../assets/discountShop/power4.svg";
import power5 from "../../assets/discountShop/power5.svg";
import power6 from "../../assets/discountShop/power6.svg";
import power7 from "../../assets/discountShop/power7.svg";
import power8 from "../../assets/discountShop/power8.svg";

import bonus1 from "../../assets/discountShop/bonus1.svg";
import bonus2 from "../../assets/discountShop/bonus2.svg";
import bonus3 from "../../assets/discountShop/bonus3.svg";
import bonus4 from "../../assets/discountShop/bonus4.svg";

const discountCards = [
  {
    headerText: "Top Trender",
    mainText:
      "Buy $1,000 worth of Bitcoin to get <strong>20% OFF</strong> on INEX",
    cryptoCoin: btc,
    price: "$1,000.00",
    backText: "",
    type: "carnival",
  },
  {
    headerText: "Shaker",
    mainText:
      "Buy $1,000 worth of Ethereum to get <strong>20% OFF</strong> on IUSD+",
    cryptoCoin: eth,
    price: "$1,000.00",
    type: "carnival",
  },
  {
    headerText: "Jumper",
    mainText:
      "Buy $5,000 worth of Doge Coin to get <strong>30% OFF</strong> on IN500",
    cryptoCoin: doge,
    price: "$5,000.00",
    type: "carnival",
  },
  {
    headerText: "Waver",
    mainText: "Buy $5,000 worth of WIBS to get <strong>30% OFF</strong> on INXC",
    cryptoCoin: satoshi,
    price: "$5,000.00",
    type: "carnival",
  },
  {
    headerText: "Bitcoin Trust",
    mainText:
      "Buy $5,000 worth of Bitcoin to get <strong>50% OFF</strong> on INEX & IUSD+",
    cryptoCoin: btc1,
    price: "$5,000.00",
    type: "bitcoin",
  },
  {
    headerText: "Bitcoin ETFX",
    mainText:
      "Buy $2,000 worth of Bitcoin to get <strong>50% OFF</strong> on INEX & WIBS",
    cryptoCoin: btc2,
    price: "$2,000.00",
    type: "bitcoin",
  },
  {
    headerText: "Bitcoin NEX-G",
    mainText:
      "Buy $500 worth of Bitcoin to get <strong>50% OFF</strong> on WIBS",
    cryptoCoin: btc3,
    price: "$500.00",
    type: "bitcoin",
  },
  {
    headerText: "Bitcoin Lotto",
    mainText:
      "Buy $200 worth of Bitcoin to get <strong>80% OFF</strong> on lotto tickets",
    cryptoCoin: btc4,
    price: "$200.00",
    type: "bitcoin",
  },
  {
    headerText: "Top Shaker",
    mainText:
      "Buy $1,000 worth of Bitcoin to get <strong>20% OFF</strong> on INEX",
    cryptoCoin: btc5,
    price: "$1,000.00",
    type: "bitcoin",
  },
  {
    headerText: "Top Shaker",
    mainText:
      "Buy $1,000 worth of Ethereum to get <strong>30% OFF</strong> on IUSD+",
    cryptoCoin: btc6,
    price: "$1,200.00",
    type: "bitcoin",
  },
  {
    headerText: "Stabler",
    mainText:
      "Buy $5,000 worth of Doge Coin to get <strong>40% OFF</strong> on IN500",
    cryptoCoin: btc7,
    price: "$1,500.00",
    type: "bitcoin",
  },
  {
    headerText: "Stabler",
    mainText:
      "Buy $5,000 worth of Doge Coin to get <strong>40% OFF</strong> on IN500",
    cryptoCoin: btc8,
    price: "$2,000.00",
    type: "bitcoin",
  },

  {
    headerText: "Speeder",
    mainText:
      "Buy $1,000 worth of INEX to get <strong>10% OFF</strong> on IUSD+",
    cryptoCoin: inex1,
    price: "$1,000.00",
    type: "inex",
  },
  {
    headerText: "Ultra",
    mainText:
      "Buy $2,000 worth of INEX to get <strong>20% OFF</strong> on IN500",
    cryptoCoin: inex2,
    price: "$2,000.00",
    type: "inex",
  },
  {
    headerText: "Extra",
    mainText:
      "Buy $30,000 worth of INEX to get <strong>70% OFF</strong> on IUSD+",
    cryptoCoin: inex3,
    price: "$30,000.00",
    type: "inex",
  },
  {
    headerText: "Exceptional",
    mainText:
      "Buy $100,000 worth of INEX to get <strong>90% OFF</strong> on INXC",
    cryptoCoin: inex4,
    price: "$100,000.00",
    type: "inex",
  },

  {
    headerText: "Starter Pack",
    mainText:
      "Buy $300 worth of Starter Pack and get <strong>$350</strong> worth of bundle",
    cryptoCoin: power1,
    price: "$300.00",
    type: "power",
  },
  {
    headerText: "Excel Pack",
    mainText:
      "Buy $500 worth of Excel Pack and get <strong>$650</strong> worth of bundle",
    cryptoCoin: power2,
    price: "$500.00",
    type: "power",
  },
  {
    headerText: "Pro Pack",
    mainText:
      "Buy $700 worth of Pro Pack and get <strong>$1,000</strong> worth of bundle",
    cryptoCoin: power3,
    price: "$700.00",
    type: "power",
  },
  {
    headerText: "Captain Pack",
    mainText:
      "Buy $1,500 worth of Captain Pack and get <strong>$3,000</strong> worth of bundle",
    cryptoCoin: power4,
    price: "$1,500.00",
    type: "power",
  },

  {
    headerText: "Copper Pack",
    mainText:
      "Buy $3,500 worth of Copper Pack and get <strong>$6,500</strong> worth of bundle",
    cryptoCoin: power5,
    price: "$3,500.00",
    type: "power",
  },
  {
    headerText: "Gold Pack",
    mainText:
      "Buy $1,500 worth of Captain Pack and get <strong>$3,000</strong> worth of bundle",
    cryptoCoin: power6,
    price: "$5,500.00",
    type: "power",
  },

  {
    headerText: "Platinum Pack",
    mainText:
      "Buy $9,00 worth of Platinum Pack and get <strong>$13,500</strong> worth of bundle",
    cryptoCoin: power7,
    price: "$900.00",
    type: "power",
  },
  {
    headerText: "Royal Pack",
    mainText:
      "Buy $15,000 worth of Royal Pack and get <strong>$22,000</strong> worth of bundle",
    cryptoCoin: power8,
    price: "$15,00.00",
    type: "power",
  },

  {
    headerText: "Bonus",
    mainText:
      "Buy $50 worth of Bonus Card to get <strong>FREE $5</strong> IUSD+",
    cryptoCoin: bonus1,
    price: "",
    type: "bonus",
  },
  {
    headerText: "Bonus",
    mainText:
      "Buy $100 worth of Bonus Card to get <strong>FREE $10</strong> IUSD+",
    cryptoCoin: bonus2,
    price: "",
    type: "bonus",
  },
  {
    headerText: "Bonus",
    mainText:
      "Buy $150 worth of Bonus Card to get <strong>FREE $15</strong> IUSD+",
    cryptoCoin: bonus3,
    price: "",
    type: "bonus",
  },
  {
    headerText: "Bonus",
    mainText:
      "Buy $250 worth of Bonus Card to get <strong>FREE $25</strong> IUSD+",
    cryptoCoin: bonus4,
    price: "",
    type: "bonus",
  },
];

export default discountCards;
