import { Button, Typography } from "antd";

import { bitgiftcard, ethgiftcard, bitxnft, ethxnft, bitgreetcard, ethgreetcard, xusd } from "./AllData";

import gift from "../../assets/digitalproducts/coverpage/gift-03.svg";
import gift_white from "../../assets/digitalproducts/coverpage/gift card_icon 1.svg";

import xnft from "../../assets/digitalproducts/coverpage/xnft icon_3 1.svg";
import xnft_white from "../../assets/digitalproducts/coverpage/xnft icon_2 1.svg";

import greet from "../../assets/digitalproducts/coverpage/greeting card icon 1.svg";
import dollar from "../../assets/digitalproducts/coverpage/dollar icon 1.svg";

import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import './MainPage.css'
// import back from "../../assets/digitalproducts/back button 2.svg";
// import back_black from "../../assets/digitalproducts/back button white 1.svg";
const { Text } = Typography;

const MainPage = () => {

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || "dark"
  );


  useEffect(() => {
    const handleStorageChange = (event:any) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('resize', handleWindowSizeChange);
    
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  console.log(theme);
  
  const isMobile = width <= 768;
  
  return (
    <>
      <div className="scan-container flex-direction-column main-page">
      {/* <div className='container' style={{paddingLeft:"169px"}}>
      {theme === "dark" ? 
      <img src={back_black} alt="" onClick={() => window.history.back()} style={{cursor:"pointer"}}/>
      :
      <img src={back} alt="" onClick={() => window.history.back()} style={{cursor:"pointer"}}/>
      }
      </div> */}

      <div className="container cover-page">

      <div className="row row-cols-1 row-cols-md-4 g-4 justify-center up-logos">
        
          <div className="col">
            <Link to="/digital-products-info/#bitcoin">
              <div className="card">
              {theme === "dark" ? 
              <img src={gift} className="card-img-top" alt="..."/>
              :
              <img src={gift_white} className="card-img-top" alt="..."/>
              }
              <div className="card-body">
                  <h5 className="card-title">Gift Card</h5>
              </div>
              </div>
            </Link>
          </div>
          <div className="col">
          <a href="/digital-products-info/#xnft">
              <div className="card">
              {theme === "dark" ? 
              <img src={xnft} className="card-img-top" alt="..."/>
              :
              <img src={xnft_white} className="card-img-top" alt="..."/>
              }
              <div className="card-body">
                  <h5 className="card-title">XNFT</h5>
              </div>
              </div>
            </a>
          </div>
          <div className="col">
          <a href="/digital-products-info/#greet-cards">
              <div className="card">
              <img src={greet} className="card-img-top" alt="..."/>
              <div className="card-body">
                  <h5 className="card-title">Greeting Card</h5>
              </div>
              </div>
            </a>
          </div>
          <div className="col">
          <a href="/digital-products-info/#xusd-sec">
              <div className="card">
              <img src={dollar} className="card-img-top" alt="..."/>
              <div className="card-body">
                  <h5 className="card-title">XUSD</h5>
              </div>
              </div>
          </a>
          </div>
      </div>
      </div>
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Indexx Shop Product Information
        </Text>
        <Text className="card__title" style={{fontSize:"22px"}}>
        All products by Indexx
        </Text>

        <Text
          className="card__title"
          id="bitcoin"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Bitcoin Bonus Shop
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
          Introducing the Bitcoin XNFT Gift Card: Unleash the Power of Crypto Gifting! Say goodbye to traditional Bonus Shop and embrace the future with Bitcoin XNFT. This cutting-edge innovation merges the incredible potential of Bitcoin with the convenience and accessibility of Bonus Shop. With Bitcoin XNFT, anyone can buy and send it, even without knowledge of crypto and the web3 world. It's the perfect opportunity for both gifting and investment.
        </Text>
        <div className="container">

        <div className="row row-cols-auto row-cols-md-3 g-4 justify-center" style={{margin:"0"}}>
              {bitgiftcard.map((item, id) => (
                <div className="col">
                  <Link to={`/digital-products-info/bitcoingift/${item.id}`}>

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title">{item.amount}</h5>
                    </div>
                    </div>
                  </Link>
                </div>
              ))}
              <div className="col"></div>
        </div>

        </div>

        <div style={{marginTop:"50px", marginBottom:"80px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/collections/bitcoin-xnft">Buy Gift Card</Link></Button>
        </div>
        
        {/* Eth Gift Card */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Ethereum Bonus Shop
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        IndexX XNFT Ethereum Gift Card is a revolutionary digital asset that encapsulates the essence of innovation, investment, and the future of money. This unique gift card combines the power of Ethereum's blockchain technology with the world of Non-Fungible Tokens (NFTs) to offer a gateway to the digital economy's forefront. With IndexX XNFT Ethereum Gift Card, recipients gain access to a universe of decentralized finance (DeFi) opportunities, digital innovation, and the evolving landscape of Web 3.0. It's more than just a gift card – it's a glimpse into the future of finance, enabling individuals to experience the potential of NFTs, invest in groundbreaking projects, and be a part of the digital revolution.
        </Text>
        <div className="container">

        <div className="row row-cols-1 row-cols-md-3 g-4 justify-center" style={{margin:"0"}}>
              {ethgiftcard.map((item, id) => (
                <div className="col">
                  <Link to={`/digital-products-info/ethgift/${item.id}`}>

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title">{item.amount}</h5>
                    </div>
                    </div>
                  </Link>
                </div>
              ))}
              <div className="col"></div>
        </div>
        </div>

        <div 
          id="xnft"
        style={{marginTop:"50px", marginBottom:"30px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/collections/ethereum-xnft">Buy Gift Card</Link></Button>
        </div>


      {/* XNFT */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          XNFT
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        A pioneering fusion of digital innovation and investment potential. Seamlessly combining NFT technology with DeFi opportunities on Ethereum's platform, it opens doors to the future of money. Explore new horizons, embrace innovation, and secure your financial journey with IndexX XNFT.
        </Text>


        {/* Bitcoin XNFT */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Bitcoin XNFT
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        Merging the prowess of Bitcoin with NFT innovation, it's a gateway to the future. Uniting investment potential with blockchain technology, this digital asset redefines wealth accumulation. Join the evolution of finance with Bitcoin IndexX XNFT and stake your claim in the crypto frontier.
        </Text>
        <div className="container">

        <div className="row row-cols-1 row-cols-md-3 g-4 justify-center xnft-main" style={{margin:"0"}}>
              {bitxnft.map((item, id) => (
                <div className="col">
                  <Link to={`/digital-products-info/bitxnft/${item.id}`}>

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title">{item.name}</h5>
                    </div>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
        </div>

        <div style={{marginTop:"70px", marginBottom:"40px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/products/bitcoin-xnft">Buy XNFT</Link></Button>
        </div>

        {/* Ethereum XNFT */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Ethereum XNFT
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        Bridging Ethereum's capabilities and NFT innovation, it's your key to the digital realm. Seamlessly combining investment avenues with cutting-edge blockchain, this digital asset reshapes future wealth. Embrace the evolution of finance with Ethereum IndexX XNFT and pave your way in the crypto landscape.
        </Text>
        <div className="container">

        <div className="row row-cols-1 row-cols-md-3 g-4 justify-center xnft-main" style={{margin:"0"}}>
              {ethxnft.map((item, id) => (
                <div className="col">
                  <Link to={`/digital-products-info/ethxnft/${item.id}`}>

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title">{item.name}</h5>
                    </div>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
        </div>

        <div 
          id="greet-cards"
        
        style={{marginTop:"50px", marginBottom:"30px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/products/ethereum-xnft">Buy XNFT</Link></Button>
        </div>
        
       {/* Greeting Cards */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Greeting Cards
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        Express your sentiments with heartwarming messages and beautiful designs. Perfect for all occasions, these cards carry your emotions and well wishes to loved ones. Spread joy, celebrate moments, and make memories with thoughtful Greeting Cards.
        </Text>


        {/* Bitcoin Greet */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Bitcoin Greeting Cards
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        Share the spirit of crypto with personalized messages. Each card holds real-time Bitcoin value, embracing innovation for memorable occasions. Give more than a sentiment – gift a piece of the financial future with Bitcoin Greeting Cards.
        </Text>
        <div className="container">

        <div className="row row-cols-1 row-cols-md-4 g-4 justify-center sub-main" style={{margin:"0"}}>
              {bitgreetcard.map((item, id) => (
                <div className="col">
                  <Link to={`/digital-products-info/bitgreet/${item.id}`}>

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
        </div>

        <div style={{marginTop:"90px", marginBottom:"60px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/collections/bitcoin-birthday-greeting-cards">Buy Greeting Card</Link></Button>
        </div>

        {/* Ethereum Greet */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          Ethereum Greeting Cards
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
        Beyond words, these cards convey heartfelt messages alongside the power of blockchain innovation. Infused with Ethereum's essence, they're more than greetings – they're a connection to the future of technology and emotion. Celebrate with Ethereum Greeting Cards and bridge the gap between sentiment and innovation.
        </Text>
        <div className="container">

        <div className="row row-cols-1 row-cols-md-4 g-4 justify-center sub-main" style={{margin:"0"}}>
              {ethgreetcard.map((item, id) => (
                <div className="col">
                  <Link to={`/digital-products-info/ethgreet/${item.id}`}>

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
        </div>

        <div 
          id="xusd-sec"
        style={{marginTop:"90px", marginBottom:"30px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/collections/ethereum-birthday-greeting-card">Buy Greeting Card</Link></Button>
        </div>

        {/* XUSD */}
        <Text
          className="card__title"
          style={{
            marginBottom:"5px",
            fontSize: "37px",
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "80px"}`,
          }}
        >
          XUSD
        </Text>

        <Text className="card__title" style={{fontSize:"15px", width:"52%", textAlign:"center", margin:"22px 0px", lineHeight:"1.4"}}>
          Coming Soon
        </Text>
        <div className="container">

        <div className="row row-cols-1 row-cols-md-2 g-4 justify-center usd-main" style={{margin:"0"}}>
              {xusd.map((item, id) => (
                <div className="col">
                  {/* <Link to={`/digital-products-info/ethgift/${item.id}`}> */}

                    <div className="card">
                    <img src={item.image} className="card-img-top" alt="..."/>
                    </div>
                  {/* </Link> */}
                </div>
              ))}
        </div>
        </div>

        <div style={{marginTop:"90px"}}>
          <Button className="cover-pg"><Link to="https://shop.indexx.ai/collections/xusd">Buy XUSD</Link></Button>
        </div>
        </div>
      <Footer></Footer>
    </>
  );
};

export default MainPage;
