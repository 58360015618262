import React, { useState } from "react";

import carnival from "../../assets/discountShop/carnival.svg";
import bitcoin from "../../assets/discountShop/bit.svg";
import inex from "../../assets/discountShop/inex.svg";
import power from "../../assets/discountShop/power.svg";
import bonus from "../../assets/discountShop/bonus.svg";

interface ITabItem {
  logo: any;
  text: string;
  active: boolean;
  onClick: () => void;
  borderColor: string;
}

interface IDiscountTabs {
  setActiveTab: (el: string) => void;
}
const TabItem = ({ logo, text, active, onClick, borderColor }: ITabItem) => {
  return (
    <div
      className={`tab-item`}
      onClick={onClick}
      // style={{ borderBottom: active ? `4px solid ${borderColor}` : "none" }}
    >
      <div className="tab-text" style={{ position: "relative" }}>
        <img src={logo} alt="Logo" />
        <div className="top">{text}</div>

        {active ? (
          <div
            style={{
              position: "absolute",
              bottom: -10,
              left: "50%",
              transform: "translateX(-50%)",
              width: "16px",
              height: "2px",
              background: borderColor,
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

function DiscountTabs({ setActiveTab }: IDiscountTabs) {
  const [activeTab, setActTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActTab(index);
    if (index === 0) {
      setActiveTab("carnival");
    } else if (index === 1) {
      setActiveTab("bitcoin");
    } else if (index === 2) {
      setActiveTab("inex");
    } else if (index === 3) {
      setActiveTab("power");
    } else if (index === 4) {
      setActiveTab("bonus");
    }
  };

  const tabsData = [
    { logo: carnival, text: "Crypto Carnival", borderColor: "#FF0FBA" },
    { logo: bitcoin, text: "Bitcoin", borderColor: "#FA8D01" },
    { logo: inex, text: "INEX Pre-sale", borderColor: "#2495FF" },
    { logo: power, text: "Power Pack", borderColor: "#FFB300" },
    { logo: bonus, text: "Bonus", borderColor: "#14A360" },
  ];

  return (
    <div className="tabs-container">
      {tabsData.map((tab, index) => (
        <TabItem
          key={index}
          logo={tab.logo}
          text={tab.text}
          active={activeTab === index}
          borderColor={tab.borderColor}
          onClick={() => handleTabClick(index)}
        />
      ))}
    </div>
  );
}

export default DiscountTabs;
