import React from "react";
import ReactCardFlip from "react-card-flip";

interface IDiscountCard {
  headerText: string;
  mainText: string;
  cryptoCoin: any;
  price: string;
  activeTab: string;
}

function DiscountCard({
  headerText,
  mainText,
  cryptoCoin,
  price,
  activeTab,
}: IDiscountCard) {
  const colorFilter = {
    headerBgColor:
      activeTab === "carnival"
        ? "#FF0FBA"
        : activeTab === "bitcoin"
        ? "#FA8D01"
        : activeTab === "inex"
        ? "#2495FF"
        : activeTab === "power"
        ? "#FFB300"
        : activeTab === "bonus"
        ? "#14A360"
        : "none",
    textColor:
      activeTab === "carnival"
        ? "#343434"
        : activeTab === "bitcoin"
        ? "#F0F0F0"
        : activeTab === "inex"
        ? "#343434"
        : activeTab === "power"
        ? "#343434"
        : activeTab === "bonus"
        ? "#343434"
        : "none",
    cardBg:
      activeTab === "carnival"
        ? "#FFC3ED"
        : activeTab === "bitcoin"
        ? "#000000"
        : activeTab === "inex"
        ? "#CCF0FF"
        : activeTab === "power"
        ? "#FFE9B3"
        : activeTab === "bonus"
        ? "#C2E7D6"
        : "none",
  };

  const [flip, setFlip] = React.useState(false);

  const flipCard = () => {
    setFlip(!flip);
  };

  const handleMouseEnter = () => {
    setFlip(true);
  };

  const handleMouseLeave = () => {
    setFlip(false);
  };

  return (
    <div
      className="discountCard_root"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ReactCardFlip flipDirection="horizontal" isFlipped={flip}>
        <div className="discountCard_root">
          <section
            className="discountCard_border"
            style={{ background: colorFilter.cardBg }}
          >
            <header
              className="discountCard_header"
              style={{ background: colorFilter.headerBgColor }}
            >
              {headerText}
            </header>
            <main
              className="discountCard_mainText"
              dangerouslySetInnerHTML={{ __html: mainText }}
              style={{ color: colorFilter.textColor }}
            ></main>
            <div className="discountCard_cryptoImg_container">
              <img
                className="discountCard_cryptoImg"
                src={cryptoCoin}
                alt={"crypto"}
              />
            </div>
            <p
              className="discountCard_price"
              style={{ color: colorFilter.textColor }}
            >
              {price}
            </p>
          </section>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="#"
              className="yellow_bg_btn"
              style={{ background: colorFilter.headerBgColor }}
            >
              Buy
            </a>
          </div>
        </div>
        <div className="discountCard_root">
          <section
            className="discountCard_border"
            style={{ background: colorFilter.cardBg }}
          >
            <header
              className="discountCard_header"
              style={{ background: colorFilter.headerBgColor }}
            >
              {headerText}
            </header>
            <div className="detailed_section discountCard_mainText">
              <p
                className="detailed_text"
                style={{ color: colorFilter.textColor }}
              >
                <strong>Title:</strong> How It Works
                <br />
                <strong>Instructions:</strong> Buy X amount of tokens. If your
                purchase falls within the following ranges, you will receive a
                discount code. Use the discount code on your INDEXX TOKENS
                purchase to get 20% off.
                <br />
                <strong>Discount Tiers:</strong>
                <br />
                - $1000 worth of purchase -&gt; 10% discount
                <br />
                - $1000-$5000 -&gt; 20% Discount
                <br />
                - $5000-$30000 -&gt; 30% Discount
                <br />
                - $30000-$50000 -&gt; 50% Discount
                <br />
                - $50000-$100000 -&gt; 70% Discount
                <br />- $100000+ -&gt; 90% Discount
              </p>
            </div>
          </section>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="#"
              className="yellow_bg_btn"
              style={{ background: colorFilter.headerBgColor }}
            >
              Buy
            </a>
          </div>
        </div>
      </ReactCardFlip>
    </div>
  );
}

export default DiscountCard;
