import React, { useEffect, useState } from "react";
import "./AirDrop.css";
import eagle from "../../assets/arts/airdrop/bitcoin_airdrop_large.png";
import eag from "../../assets/arts/airdrop/stars.png";

import form from "../../assets/arts/airdrop/form.png";
import bigbee from "../../assets/arts/airdrop/bee new-min.png";
import dragon from "../../assets/arts/airdrop/bitcoin_airdrop_large.png";

import mobile_bee from "../../assets/arts/airdrop/bee-mobile.png";
import x from "../../assets/arts/airdrop/x.png";
import x_dark from "../../assets/arts/airdrop/x_dark.png";
import fb from "../../assets/arts/airdrop/fb.png";
import hive from "../../assets/arts/airdrop/hive.png";
import scan from "../../assets/arts/airdrop/scan.png";
import inex from "../../assets/token-icons/IUSD+.png";
import btc from "../../assets/token-icons/bitcoin_icon.svg";
import cal from "../../assets/arts/airdrop/cal-img.png";
import caliusd from "../../assets/arts/airdrop/cal-iusd.svg";

import cal_dark from "../../assets/arts/airdrop/btc_dark.svg";
import cal_light from "../../assets/arts/airdrop/btc_light.svg";
import cal_mob from "../../assets/arts/airdrop/cal_mob.png";
import cal_mob_dark from "../../assets/arts/airdrop/mob_dark_iusd.png";
import cal_mob_light from "../../assets/arts/airdrop/mob_light_iusd.png";
import CountdownTimer from "./CountdownTimer";
import ReadOnlyCalendar from "./ReadOnlyCalendar";
import ContactUs from "./ContactUs/ContactUs";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { getCryptoPrice } from "../../services/api";

const BtcAirDrop = () => {
  const targetDate = new Date("2024-03-14T23:59:59");

  const isMobile = useMediaQuery("(max-width:768px)");

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );
  const [btcPrice, setBtcPrice] = useState();

  const getLatestBTCPrice = async () => {
    try {
      const res = await getCryptoPrice('BTC');
      setBtcPrice(res.data.lastPrice);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    getLatestBTCPrice();
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "70px" }}
    >
      <div style={{ maxWidth: `${isMobile ? "84%" : "1550px"}` }}>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: isMobile ? "100%" : "50%" }}>
            {
              <div style={{ width: "100%" }}>
                <img alt="eagle" src={dragon} width="100%" />
              </div>
            }

            {/* {
              <div className="eagle-img">
                <img alt="eagle" src={mobile_bee} />
              </div>
            } */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : "49%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isMobile ? "" : "120px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className={
                  isMobile ? "font_47x fw-bold text-center" : "font_60x fw-bold"
                }
              >
                FREE BITCOIN AIRDROP
              </div>
              <div className={isMobile ? "font_16x" : "font_20x"}>
                Exciting News! Participate in the BITCOIN Airdrop and claim your
                share of the future! Simply follow the steps below to secure
                your Bitcoin tokens
              </div>
              <div style={{ margin: "70px auto 30px" }}>
                <CountdownTimer targetDate={targetDate} />
              </div>
              <Button
                variant="contained"
                component={Link}
                to="/airdrop-register-bitcoin"
                disableTouchRipple
                sx={{
                  backgroundColor: "#F59219",
                  borderRadius: "2px",
                  // color: "#282828",
                  width: `${isMobile ? "100%" : "316px"}`,
                  px: 10,
                  py: 1,
                  ml: `${isMobile ? "0%" : "27.2%"}`,
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",
                  mt: 5,
                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    backgroundColor: "#F59219",
                    boxShadow: "none",
                    color: "#fff",
                  },
                }}
              >
                Register for airdrop
              </Button>
              {/* <a className="timer-link" href="/airdrop-register">Register for airdrop &gt;</a> */}
            </div>
            <div style={{ alignSelf: "center", margin: "100px 0px" }}>
              {isMobile ? (
                <div className="d-flex align-items-center flex-column">
                  <img alt="inex" src={btc} width={"162px"} />
                  <div className={isMobile ? "font_100x fw-bold" : "font_200x"}>
                    ${new Intl.NumberFormat('en-US').format(Math.floor(btcPrice))}
                  </div>
                  <div className="font_20x">Estimated Value</div>
                </div>
              ) : (
                <div>
                  <div className="font_20x">
                    <img alt="inex" src={btc} width={"62px"} /> Estimated Value
                  </div>
                  <div className="font_150x">${new Intl.NumberFormat('en-US').format(Math.floor(btcPrice))}</div>
                </div>
              )}
            </div>

            <div className="what-inex-1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isMobile && <img alt="inex" src={btc} width={"62px"} />}
              </div>

              <div className={isMobile ? "font_37x fw-bold" : "font_70x"}>
                What is Bitcoin Token
              </div>
              <div className="font_17x">
                Bitcoin (BTC) is a decentralized digital currency that was
                created in 2009 by an anonymous person or group of people using
                the pseudonym Satoshi Nakamoto. It was the first cryptocurrency
                to gain widespread recognition and remains the most well-known
                and valuable cryptocurrency to date.
              </div>
              {/* <div className="my-5">
                <a
                  style={{ color: "#2F7809" }}
                  href="/indexx-exchange/token-details/usd"
                >
                  Learn More &gt;
                </a>
              </div> */}
            </div>
          </div>
        </div>

        {/* Above component  */}
        <div
          style={{
            width: "100%",
            maxWidth: "950px",
            margin: "100px auto 50px",
          }}
        >
          <h3
            style={{
              fontSize: !isMobile ? "70px" : "32px",
              fontWeight: "bold",
            }}
          >
            How does this Airdrop Work?
          </h3>
          <p style={{ fontSize: !isMobile ? "20px" : "18px" }}>
            To participate in the airdrop, kindly follow the registration steps
            outlined below:
          </p>
          <br />
          <br />
          <h4
            style={{
              fontSize: !isMobile ? "25px" : "20px",
              fontWeight: "bold",
            }}
          >
            Step 1: Follow us on Twitter&#8232;&#8232;
          </h4>
          <br />

          <p style={{ fontSize: !isMobile ? "20px" : "18px" }}>
            {" "}
            Please visit the following link: Twitter.com/indexx_ai and click on
            the &quot;Follow&quot; button to follow our official Twitter
            account. This will ensure that you receive all the latest updates
            and announcements regarding the airdrop.&#8232;
          </p>

          <br />
          <br />
          <h4
            style={{
              fontSize: !isMobile ? "25px" : "20px",
              fontWeight: "bold",
            }}
          >
            {" "}
            Step 2: Purchase a Ticket and Claim Free Rewards
          </h4>
          <br />

          <p style={{ fontSize: !isMobile ? "20px" : "18px" }}>
            {" "}
            To proceed with the registration process, please visit
            lotto.indexx.ai. Once there, you can claim free rewards by
            purchasing a ticket. This will grant you entry into the airdrop and
            give you a chance to win exciting prizes.
          </p>
          <br />
          <br />
          <h4
            style={{
              fontSize: !isMobile ? "25px" : "20px",
              fontWeight: "bold",
            }}
          >
            Step 3: Register with your Email Address
          </h4>
          <br />

          <p style={{ fontSize: !isMobile ? "20px" : "18px" }}>
            After you have followed us on Twitter and purchased a ticket to
            claim your free rewards, please click on the &quot;Register&quot;
            button on the website. This will prompt a registration form to
            appear. Enter your email address in the designated field and submit
            the form.
          </p>
          <br />
          <p style={{ fontSize: !isMobile ? "20px" : "18px" }}>
            {" "}
            By completing this final step, you will successfully register for
            the airdrop and ensure that you are eligible for the rewards. We
            appreciate your participation and support. If you have any further
            questions or require assistance, please feel free to contact our
            customer support team.
          </p>

          <img
            alt="cal"
            src={theme === "dark" ? cal_dark : cal_light}
            style={{ width: "100%" }}
          />
        </div>

        <div className="my-calendar">
          {isMobile ? (
            <>
              <div className="font_50x fw-bold align-self-start">Key dates</div>
              <div className="font_31x align-self-start">for airdrop</div>
              <div className="font_20x mb-5 align-self-start">
                Don’t miss out any of these dates
              </div>
            </>
          ) : (
            <>
              <div className="font_70x fw-bold">Key dates for airdrop</div>
              <div className="font_20x mb-5 ">
                Don’t miss out any of these dates
              </div>
            </>
          )}
          <ReadOnlyCalendar />
        </div>

        <div className="req">
          <div className="req-desc">
            <div className="font_60x fw-bold">Airdrop requirements</div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={fb}
                alt="fb"
                style={{ marginLeft: "10px", marginRight: "30px" }}
              />
              1. Join our official Facebook community
              <div className="font_15x ind-req-desc">
                <a href="https://www.facebook.com/profile.php?id=100086225564460">
                  https://www.facebook.com/profile.php?id=100086225564460
                </a>
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={theme === "dark" ? x_dark : x}
                alt="x"
                style={{ marginLeft: "15px", marginRight: "30px" }}
              />
              2. Follow us on Twitter
              <div className="font_15x ind-req-desc">
                <a href="https://twitter.com/Indexx_ai">
                  https://twitter.com/Indexx_ai
                </a>
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={hive}
                alt="hive"
                style={{ marginLeft: "15px", marginRight: "23px" }}
              />
              3. Join Indexx Hive on Facebook
              <div
                className={
                  isMobile ? "font_15x ind-req" : "font_15x ind-req-desc"
                }
              >
                <img src={scan} alt="hive" />
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={form}
                alt="form"
                style={{ marginLeft: "10px", marginRight: "23px" }}
              />
              4. Complete the registration form
            </div>
            <Button
              variant="contained"
              component={Link}
              to="/airdrop-register-bitcoin"
              disableTouchRipple
              sx={{
                backgroundColor: "#F59219",
                borderRadius: "2px",
                // color: "#282828",
                width: `${isMobile ? "100%" : "316px"}`,
                px: 10,
                py: 1,
                ml: `${isMobile ? "0%" : "14%"}`,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 700,
                boxShadow: "none",
                mt: 5,
                height: `${isMobile ? "55px" : "40px"}`,
                "&:hover": {
                  backgroundColor: "#F59219",

                  boxShadow: "none",
                  color: "#fff",
                },
              }}
            >
              Register Now
            </Button>
          </div>
          <div style={{ marginTop: "-40%" }}>
            <img src={eag} alt="eagle" className="req-img" />
          </div>
        </div>

        <div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default BtcAirDrop;
