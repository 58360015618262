import React, { useContext } from "react";
import commingSoon from "../../../assets/meme/sorekt_comingsoon.png";
import commingSoonDark from "../../../assets/meme/sorekt_dark_coming_soon.png";
import { Theme } from "../../../utils/themeContext";
const SorektComingSoon = () => {
  const themeContest = useContext(Theme);
  const theme = themeContest?.theme ?? "dark";
  return (
    <div
      style={{
        width: "100%",
        margin: "150px auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={theme === "light" ? commingSoon : commingSoonDark}
        style={{ width: "70%" }}
      />
    </div>
  );
};

export default SorektComingSoon;
