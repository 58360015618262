import React, { useEffect, useState } from "react";
import CrossIcon from "../../assets/homepage/updated/cross_white.png";

const CustomSnackbar = ({
  openPopup,
  setOpenPopup,
  icon,
  heading,
  paragraph,
  buttonArr,
}) => {
  const [is0Hovered, setIs0Hovered] = useState(false);
  const [is1Hovered, setIs1Hovered] = useState(false);

  const handleMouseEnter = (i) => {
    i === 0 ? setIs0Hovered(true) : setIs1Hovered(true);
  };

  const handleMouseLeave = (i) => {
    i === 0 ? setIs0Hovered(false) : setIs1Hovered(false);
  };

  useEffect(() => {
    if (openPopup) {
      setTimeout(() => {
        setOpenPopup(false);
      }, 10000);
    }
  }, [openPopup]);

  const button0styles = (el) => {
    return {
      borderRadius: "2px",
      color: "white",
      fontSize: "13px",
      marginBottom: "10px",
      ...el.style,
      // Apply hover styles based on the isHovered state
      ...(is0Hovered && { ...el.style?.hover }),
    };
  };
  const button1styles = (el) => {
    return {
      borderRadius: "2px",
      color: "white",
      fontSize: "13px",
      marginBottom: "10px",
      ...el.style,
      // Apply hover styles based on the isHovered state

      ...(is1Hovered && { ...el.style?.hover }),
    };
  };

  return (
    <>
      {openPopup && (
        <div className="modal-backdrop" onClick={() => setOpenPopup(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div
              className="modal-body"
              style={{
                width: "100%",
                maxWidth: "800px",
                margin: "auto",
                position: "relative",
              }}
            >
              <div className="modals-container text-center">
                <div
                  className="modals-item"
                  style={{ background: "rgba(255,255,255,1)" }}
                >
                  <button
                    className="close-btn-modl"
                    onClick={() => setOpenPopup(false)}
                  >
                    <img src={CrossIcon} />
                  </button>

                  <div style={{ padding: "22px 0px" }}>
                    <img src={icon} alt="Image 1" height={120} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 30px ",
                      color: "black",
                    }}
                  >
                    <h3 style={{ color: "black" }}>{heading}</h3>
                    <p>{paragraph}</p>
                    <div style={{ marginBottom: "50px" }}></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                      gap: "10px",
                    }}
                  >
                    {buttonArr?.map((el, i) => (
                      <button
                        onClick={() =>
                          el.close
                            ? setOpenPopup(false)
                            : (window.location.href = el.href)
                        }
                        className="modals-button"
                        style={i === 0 ? button0styles(el) : button1styles(el)}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={() => handleMouseLeave(i)}
                      >
                        {el.text}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSnackbar;
