import { Image, Button, Spin } from "antd";
import { Typography } from "antd";
import { useState } from "react";
import firstcoin from "../../assets/how-it-works/hivee.svg";
import Footer from "../Footer/Footer";
import { baseDEXURL } from "../../services/api";

const { Text } = Typography;

const HowHive = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <div className="scan-container how-it-works flex-direction-column">
        <Spin spinning={!imageLoaded} size="large" wrapperClassName="spinner-wrapper">
          <Image
            preview={false}
            src={firstcoin}
            className={`rounded mx-auto d-block ${imageLoaded ? 'loaded' : 'hidden'}`}
            style={{ paddingTop: 40, display: "flex" }}
            onLoad={() => setImageLoaded(true)}
          />
        </Spin>

        <br />
        <br />
      </div>

      <Footer />
    </>
  );
};

export default HowHive;
