import React from "react";
import { Link } from "react-router-dom";
import ordinalCoin from "../../../assets/meme/ordinal.png";

import soRekt from "../../../assets/meme/sorekt.png";
import coin from "../../../assets/meme/coin_ordinal.png";
import "./style.css"; // import your CSS file for styling
import IconicHeader from "./IconicHeader";
import Footer from "../../Footer/Footer";

const OrdinalInner = () => {
  return (
    <div>
      <IconicHeader type="ordinal" />
      <section
        style={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          margin: "100px auto 0px auto",
          gap: "15px",
        }}
      >
        <div>
          <img src={ordinalCoin} height="100px" />
        </div>
        <p style={{ fontSize: "40px", fontWeight: "bold" }}>
          Ordinals Projects
        </p>
      </section>
      <section className="project-section">
        {/* <h1 className="main-heading">Projects</h1> */}
        <div className="project-container">
          {/* Project Cards */}
          <div className="project-card">
            <a href="https://test.whoisbitcoinsatoshi.wtf/ordinals" target="_blank">
              <img src={coin} alt="Project 1" />
            </a>

            <div>
              <h2>Bitcoin Satoshi Ordinal</h2>
              <p>
                AI based Ordinals generated which uses state of the art AI
                models based on the bitcoin blockchain and the mystery of
                satoshi nakamoto
              </p>
            </div>
            <a
              href="https://test.whoisbitcoinsatoshi.wtf/ordinals"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="/sorekt-coming-soon">
              <img src={soRekt} alt="Project 1" />
            </a>

            <div>
              <h2>SoRekt</h2>
              <p>
                AI based Ordinals generated which uses state of the art AI
                models based on the bitcoin blockchain based on the scams and
                illegal stuff happening in crypto
              </p>
            </div>
            <a
              href="/sorekt-coming-soon"
              className="learn-more-button"
              // target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          {/* Repeat the above project card structure for other projects */}
        </div>
      </section>
      <Footer helpIcon={false} />
    </div>
  );
};

export default OrdinalInner;
